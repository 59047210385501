import { Divider, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import ImageAnnotatorComponent from "../../../../Common/ImageAnnotator/ImageAnnotatorComponent";
import i18next from "i18next";

export const ViewGeneratorDevExpressImageAnnotatorPreview = ({ t, previewImageAnnotatorFile, setPreviewImageAnnotatorFile }) => {

  return <>
    {previewImageAnnotatorFile && (
      <Modal
        open={true}
        width={"80%"}
        destroyOnClose
        closable
        onCancel={() => setPreviewImageAnnotatorFile(undefined)}
        title={<Typography.Title level={5}>{t(`documents.drawer.viewFiles`)}</Typography.Title>}
        footer={null}
      >
        <Divider />

        <ImageAnnotatorComponent
          url={previewImageAnnotatorFile.url}
          allowModify={false}
          allowClaim={false}
          currentAnnotations={JSON.parse(previewImageAnnotatorFile.annotations || "[]")}
          submissionId={previewImageAnnotatorFile.submissionId}
        />
      </Modal>
    )}
  </>

}
