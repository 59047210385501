import { theme } from "antd";

export const themeConfig = () => {
    const storedTheme = localStorage.getItem("theme");
    const currentPlatform = localStorage.getItem("platform");
    const isLightTheme = (storedTheme === "light" || !storedTheme || currentPlatform === "mobile");

    return {
        algorithm: isLightTheme ? theme.defaultAlgorithm : theme.darkAlgorithm,
        token: {
            colorPrimary: "#778da9",
            colorWarning: "#ec547a",
            colorSuccess: "#5bceae",
            colorError: "rgb(236, 84, 122)",
            colorBorderBase: "#90A8BE",
            colorBgLayout: isLightTheme ? "#F7F8F9" : "black",
        },
        currentTheme: isLightTheme ? 'light' : 'dark', 
        hashed: false
    }
};