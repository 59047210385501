import { Button, Dropdown, Flex, message, Popover, Tag, Tooltip, Typography, Upload } from "antd";
import { getPartText, isValidJSON } from "../../../../utils";

import * as AntdIcons from "@ant-design/icons";
import { FileApproveModal } from "../components/FileApproveModal";

import Icon, { EyeOutlined } from "@ant-design/icons";
import { find, includes, isEmpty, isNil, round } from "lodash";
import moment from "moment";

import GraphQlService from "../../../../Common/GraphQlService";
import { gql } from "@apollo/client";
import ViewGeneratorStore from "../../../ViewGeneratorStore";
import i18next from "i18next";
import { useState } from "react";
import ViewGeneratorDevExpressPreviewFile from "./ViewGeneratorDevExpressPreviewFile";
import { ViewGeneratorDevExpressImageAnnotatorPreview } from "./ViewGeneratorDevExpressImageAnnotatorPreview";

const columnRender = {
  file: "file",
  imageAnnotator: "image-annotator",
  tagRegex: /tag:\s*(.*)/,
  jsonRegex: /json:\$\.\s*(.*)/,
  tag: "tag:",
  json: "json:",
  moment: "moment:",
  round: "round:",
  bool: "bool",
  status: "status",
  fileApprove: "fileApprove",
  fileApproveB: "fileApproveB",
  euro: "euro",
  dollar: "dollar",
  pound: "pound"
};

export const CellRender = ({ render, cellData }) => {
  const [previewImageAnnotatorFile, setPreviewImageAnnotatorFile] = useState();

  const t = i18next.t;
  const cellDataParsedValue = extractValue(cellData);

  if (!render) render = "";

  if (includes(render, columnRender.status)) {
    if (!render.split("status:")[1]) return "";

    const statusRenderConfig = JSON.parse(render.split("status:")[1]);
    const currentCellConfig = find(statusRenderConfig, ({ value }) => value === cellData.value);

    if (!currentCellConfig) {
      return <Tag style={{ width: "94%", textAlign: "center" }}>{cellData.value.label}</Tag>;
    }

    if (!currentCellConfig && (isNil(cellData.value) || isEmpty(cellData.value))) return null;

    return (
      <Tag
        color={currentCellConfig.color}
        icon={currentCellConfig?.icon && <Icon component={AntdIcons[`${currentCellConfig?.icon}`]} />}
        style={{ width: "93%", textAlign: "center" }}
      >
        {isEmpty(currentCellConfig?.label) ? cellData.value : currentCellConfig.label}
      </Tag>
    );
  }

  // = = File render handing
  if (render === columnRender.bool) {
    //  return cellData.value ? <CheckBox disabled value={true} /> : <CheckBox disabled value={false} />;
    return cellData.value ? <AntdIcons.CheckOutlined /> : <></>;
  }

  if (render === columnRender.file) {
    // = = File uploaded
    if (cellData.text) return <ViewGeneratorDevExpressPreviewFile props={{ t }} />;

    // = = No file
    if (!cellData.text)
      return (
        <Typography.Text strong style={{ color: "red" }}>
          -
        </Typography.Text>
      );
  }

  if (render === columnRender.imageAnnotator) {
    // = = File uploaded
    if (cellData.text)
      return (
        <>
          <ViewGeneratorDevExpressImageAnnotatorPreview
            t={t}
            setPreviewImageAnnotatorFile={setPreviewImageAnnotatorFile}
            previewImageAnnotatorFile={previewImageAnnotatorFile}
          />
          <Button
            type="text"
            size="small"
            onClick={() => {
              setPreviewImageAnnotatorFile &&
                setPreviewImageAnnotatorFile({ ...cellData.value, containerId: cellData.data.id });
            }}
            icon={<EyeOutlined />}
          />
        </>
      );

    // = = No file
    if (!cellData.text)
      return (
        <Typography.Text strong style={{ color: "red" }}>
          -
        </Typography.Text>
      );
  }

  // = = Render tag
  if (render.includes(columnRender.tag) && cellData.value) {
    if (getPartText(columnRender.tagRegex, render)) {
      if (
        cellDataParsedValue.isSingle &&
        typeof cellDataParsedValue.value === "string" &&
        !cellDataParsedValue?.value.includes(",")
      ) {
        return <Tag color={getPartText(columnRender.tagRegex, render)}>{cellDataParsedValue.value}</Tag>;
      } else if (
        cellDataParsedValue.isSingle &&
        typeof cellDataParsedValue.value === "string" &&
        cellDataParsedValue?.value?.includes(",")
      ) {
        return (
          <>
            {cellDataParsedValue.value.split(",").map(v => (
              <Tag key={"tag_" + v} color={getPartText(columnRender.tagRegex, render)}>
                {v}
              </Tag>
            ))}
          </>
        );
      } else if (cellDataParsedValue.isSingle && typeof cellDataParsedValue.value === "number") {
        return <Tag color={getPartText(columnRender.tagRegex, render)}>{cellDataParsedValue.value}</Tag>;
      } else {
        return (
          <>
            {cellDataParsedValue.value.map(v => (
              <Tag key={"tag_" + v} color={getPartText(columnRender.tagRegex, render)}>
                {v}
              </Tag>
            ))}
          </>
        );
      }
    }

    // -- no color specified hanlder
    if (cellDataParsedValue.isSingle && !cellDataParsedValue.value.includes(",")) {
      return <Tag>{cellDataParsedValue.value}</Tag>;
    } else if (cellDataParsedValue.isSingle && cellDataParsedValue.value.includes(",")) {
      return (
        <>
          {cellDataParsedValue.value.split(",").map(v => (
            <Tag key={"tag_" + v}>{v}</Tag>
          ))}
        </>
      );
    } else {
      return (
        <>
          {cellDataParsedValue.value.map(v => (
            <Tag key={"tag_" + v}>{v}</Tag>
          ))}
        </>
      );
    }
  }

  // = = Render JSON
  if (render.includes(columnRender.json) && cellData.value) {
    if (isValidJSON(cellData.value)) {
      const match = getPartText(columnRender.jsonRegex, render);
      const parse = JSON.parse(cellData.value)[`${match}`];
      return <Typography.Text>{parse}</Typography.Text>;
    }
  }

  // = = Moment
  var renderSplit = render.split(":");
  if (render.includes(columnRender.moment) && cellData.value) {
    return <Typography.Text>{moment(cellData.value).format(renderSplit[1])}</Typography.Text>;
  }

  // = = Round
  if (render.includes(columnRender.round) && cellData.value) {
    return <Typography.Text>{round(cellData.value, +renderSplit[1])}</Typography.Text>;
  }

  // = = Currency
  if (render.includes(columnRender.euro) && cellData.value) {
    const euroFormat = new Intl.NumberFormat("es-ES", { style: "currency", currency: "EUR" });
    return euroFormat.format(cellData.value);
  }
  if (render.includes(columnRender.dollar) && cellData.value) {
    const dollarFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
    return dollarFormat.format(cellData.value);
  }
  if (render.includes(columnRender.pound) && cellData.value) {
    const poundFormat = new Intl.NumberFormat("en-UK", { style: "currency", currency: "GBP" });
    return poundFormat.format(cellData.value);
  }

  let status = "check";

  const custom = async e => {
    const url = await ViewGeneratorStore.uploadFile(e.file, e.file.name);

    await ViewGeneratorStore.updateContainerProperty(
      cellData.data.id,
      cellData.fieldId,
      JSON.stringify({ status, url })
    ).finally(() => {
      message.success(i18next.t("tasks.stepTitles.finished"));
    });
    ViewGeneratorStore.loadData();
  };

  let uploadProps = {
    name: "file",
    multiple: false,
    customRequest: custom,
    showUploadList: false,
    style: {
      width: ""
    }
  };

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  // = = File comment and approve
  if (includes(render, columnRender.fileApprove)) {
    // = = File uploaded

    let parsedText = isValidJSON(cellData.text) ? JSON.parse(cellData.text) : undefined;

    if (parsedText && parsedText.status && !isNil(parsedText.status)) {
      return <FileApproveModal cellData={cellData} />;
    }

    // = = No file

    if (!parsedText?.status) {
      return (
        <Popover
          open={isPopoverOpen}
          trigger={"hover"}
          onOpenChange={() => {
            setTimeout(() => {
              setIsPopoverOpen(prev => !prev);
            }, 500);
          }}
          content={
            <Button
              onClick={async e => {
                await ViewGeneratorStore.updateContainerProperty(
                  cellData.data.id,
                  cellData.fieldId,
                  JSON.stringify({ status: "notRequired" })
                ).finally(() => {
                  message.success(i18next.t("tasks.stepTitles.finished"));
                });
                ViewGeneratorStore.loadData();
              }}
              icon={<AntdIcons.MinusCircleOutlined />}
            >
              {i18next.t("fileApprove.notRequired")}
            </Button>
          }
        >
          <Upload.Dragger {...uploadProps}>
            <AntdIcons.PlusOutlined style={{ color: "rgba(11,11,11,0.2)" }} />
          </Upload.Dragger>
        </Popover>
      );
    }
  }

  if (cellDataParsedValue.isSingle) {
    if (cellData?.column?.dataType === "date") return moment(cellDataParsedValue.value).toString();
    return cellDataParsedValue.value;
  } else {
    if (cellData?.column?.dataType === "date") return cellDataParsedValue.value.map(i => <>{moment(i).toString()}</>);
    return cellDataParsedValue.value.map(i => i);
  }
};

const extractValue = cellData => {
  if (!cellData.value) return { isSingle: false, value: [] };

  if (Object.prototype.toString.call(cellData.value) === "[object Array]") {
    var arr = Array(cellData.value);
    if (arr.length === 0) return { isSingle: true, value: "" };

    var firstItemType = typeof arr[0][0];
    if (firstItemType !== "object") return { isSingle: true, value: cellData.value };

    return { isSingle: false, value: arr[0].map(x => x.label) };
  } else if (Object.prototype.toString.call(cellData.value) === "[object Object]") {
    return { isSingle: true, value: cellData.value.label };
  } else {
    //if (`${cellData.value}`.includes(",")) return { isSingle: false, value: cellData.value.split(",") };
    //else
    return { isSingle: true, value: cellData.value };
  }
};
