import { useEffect, useState } from "react";
import { Button, Divider, Modal, Typography } from "antd";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";

const ViewGeneratorDevExpressPreviewFile = props => {
  const [previewFile, setPreviewFile] = useState(false);

  const handleDownloadPreviewFile = async () => {
    const path = localStorage.getItem(`host`) + "/api/files?path=" + previewFile;
    const link = document.createElement("a");

    link.href = path;

    link.setAttribute("download", path.split("/")[path.split("/").length - 1]);
    link.style.display = "none";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Button type="text" size="small" onClick={() => setPreviewFile(true)} icon={<EyeOutlined />} />
      <Modal
        open={previewFile}
        destroyOnClose
        closable
        onCancel={() => setPreviewFile(false)}
        title={
          <Typography.Title level={5}>
            {props.t(`documents.drawer.viewFiles`)}
            <DownloadOutlined style={{ marginLeft: 15 }} onClick={handleDownloadPreviewFile} />
          </Typography.Title>
        }
        footer={null}
      >
        <Divider />
        <img width="100%" height="100%" src={(!previewFile.includes('http') ? `${localStorage.getItem(`host`)}/` : '') + previewFile} />
      </Modal>
    </>
  );
};

export default withTranslation()(ViewGeneratorDevExpressPreviewFile);
