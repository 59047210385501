import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import ViewGeneratorStore from "../../ViewGeneratorStore";
import { groupBy, keys, lowerFirst, slice, take, uniqBy } from "lodash";
import { Table } from "antd";
import ViewGeneratorButtonsRenderer from "../ViewGeneratorButtonsRenderer";
import GraphQlService from "../../../Common/GraphQlService";
import { graphql } from "graphql";

const ViewGeneratorDataGridCompareComponent = ({ containerIds, linkConfig, assetDefinition }) => {
  console.log("ViewGeneratorDataGridCompareComponent", containerIds, linkConfig, assetDefinition);
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const graphQlService = new GraphQlService();

  useEffect(() => {
    if (!containerIds) {
      generateData(
        ViewGeneratorStore.data["containers"]
          .map(c => ({ ...c, payload: JSON.parse(c.payload || "{}") }))
          .filter(x => ViewGeneratorStore.selectedContainerIds.includes(x.rootId)),
        ViewGeneratorStore.hubConfig,
        ViewGeneratorStore.assetDefinition
      );
    } else loadRelatedData();
  }, []);

  const generateData = (containers, hubConfig, assetDef) => {
    console.log("generate data", toJS(containers), toJS(hubConfig), toJS(assetDef));

    var preColumns = [{ key: "field", dataIndex: "field", render: text => <b>{text}</b> }];
    containers.forEach((sd, i) => preColumns.push({ key: sd.id, dataIndex: `column${i}` }));
    setColumns(preColumns);

    var preDataSource = [];

    const f = keys(hubConfig.fields)
      .filter(x => hubConfig.fields[x].tableVisible === true)
      .map(f => assetDef.fields.find(x => x.id === f));

    f.forEach(f => preDataSource.push({ field: f.label, dbTableLocation: f.dbTableLocation }));

    preDataSource.forEach(ds => {
      slice(preColumns, 1, preColumns.length).forEach(c => {
        var data = containers.find(x => x.id === c.key);

        if (ds.dbTableLocation.includes("Payload$"))
          ds[c.dataIndex] = data.payload[ds.dbTableLocation.replace("Payload$", "")];
        else ds[c.dataIndex] = data[lowerFirst(ds.dbTableLocation)];
      });
    });

    var footer = { field: "" };
    containers.forEach((sd, i) => {
      footer[`column${i}`] = (
        <ViewGeneratorButtonsRenderer
          buttons={hubConfig.tableRowButtons}
          record={sd}
          displayOption={{ icon: true, tooltip: true, text: false }}
        />
      );
    });

    preDataSource.push(footer);

    setDataSource(preDataSource);
  };

  const loadRelatedData = async () => {
    console.log("load related data", containerIds);
    // linkConfig = relation asset definition => presupesto 2 oferta
    // assetDefinition = relation asset definition => paquete 2 oferta

    const hubDataResponse = await graphQlService.get(
      `{ uiHub(id: "${assetDefinition.relatedAssetDefinition.id}") { payload } }`
    );
    var hubConfig = JSON.parse(hubDataResponse.data["uiHub"].payload || "{}");
    // hubConfig = hub config of oferta

    // = = = Generate columns for "cost items"
    var newColumns = [];
    // keys(hubConfig.fields).forEach((hcf, i) => {
    //   var assetDef = linkConfig.fields.find(x => x.id === hcf)
    //   if (assetDef != null) {
    //     newColumns.push({
    //       title: assetDef.label,
    //       dataIndex: `column${hcf}`,
    //       key: hcf,
    //       assetDef
    //     });
    //   }
    // })

    var costItemDescField = linkConfig.relatedAssetDefinition.fields.find(
      x => x.id === "842e033c-c900-4439-9243-85ba65a9c3e9" // WARNING: hardcoded field id
    );

    newColumns.push({
      title: costItemDescField.label,
      dataIndex: costItemDescField.dbTableLocation,
      key: costItemDescField.id
    });

    // = = = Generate columns for "offers"
    var triggerDataQuery = [];
    containerIds.forEach(cId => triggerDataQuery.push(`{ path:"RootId", comparison: EQUAL, value: "${cId}" }`));
    var headers = await graphQlService.get(
      `{containers(where:[${triggerDataQuery.join(`,`)}], whereConnector: "OR"){ id rootId name payload }}`
    );

    console.log("headers", toJS(headers), toJS(hubConfig), toJS(assetDefinition));

    headers.data.containers.forEach(c => {
      newColumns.push({
        title: c.name,
        dataIndex: `${c.id}_Payload$ProposedValue`,
        key: c.id,
        render: (v, r, i) => {
          if (!v) return <></>;

          if (v.toString().includes(`button_`))
            return (
              <ViewGeneratorButtonsRenderer
                buttons={hubConfig.tableRowButtons}
                record={c}
                displayOption={{ icon: true, tooltip: true, text: false }}
              />
            );
          return <>{r[`${c.id}_Payload$ProposedValue`]}</>;
        }
      });
    });

    // = = = Actions
    newColumns.push({
      title: ``,
      key: `actions`
    });
    setColumns(newColumns);

    console.log("columns", newColumns);

    var containersResponses = [];
    for (let i = 0; i < containerIds.length; i++) {
      const containersResponse = (
        await graphQlService.get(
          `{ containerRelationsHub(containerId:"${containerIds[i]}", assetDefinitionId:"${assetDefinition.relatedAssetDefinition.id}", relationId:"${linkConfig.id}") { payload relatedContainer { id name payload } } }`
        )
      ).data.containerRelationsHub.filter(x => x.relatedContainer);

      containersResponses = [
        ...containersResponses,
        ...containersResponse.map(x => ({
          ...x,
          containerId: containerIds[i],
          payload: JSON.parse(x.payload || "{}"),
          relatedContainer: {
            ...x.relatedContainer,
            payload: JSON.parse(x.relatedContainer.payload || "{}")
          }
        }))
      ];
    }
    var costItemsGroups = groupBy(containersResponses, x => x.relatedContainer.id);

    var newData = [];

    var hardcodedFields = ["ef539142-6ea2-44aa-9e43-85bad913b389"];
    hardcodedFields.forEach(fieldId => {
      var field = assetDefinition.fields.find(x => x.id === fieldId);

      var newItem = {};
      newItem[`Payload$Summary`] = field.label;

      headers.data.containers.forEach(c => {
        if (field.dbTableLocation.includes("Payload$")) {
          var payload = JSON.parse(c.payload);
          newItem[`${c.rootId}_Payload$ProposedValue`] = payload[field.dbTableLocation.split("$")[1]];
        } else newItem[`${c.rootId}_Payload$ProposedValue`] = c[field.dbTableLocation];
      });

      newData.push(newItem);
    });

    newData.push({});

    keys(costItemsGroups).forEach(costItemsGroupsKey => {
      var groupData = {};

      costItemsGroups[costItemsGroupsKey].forEach(item => {
        keys(item.payload).forEach(payloadKey => {
          groupData[`${item.containerId}_Payload$${payloadKey}`] = item.payload[payloadKey];
        });

        keys(item.relatedContainer.payload).forEach(payloadKey => {
          groupData[`Payload$${payloadKey}`] = item.relatedContainer.payload[payloadKey];
        });
      });
      newData.push(groupData);
    });

    var buttonsRow = {};
    containerIds.forEach(cid => (buttonsRow[`${cid}_Payload$ProposedValue`] = `button_`));
    newData.push(buttonsRow);

    console.log("data", newData);
    setDataSource(newData);
  };

  return (
    <Table
      size="small"
      columns={columns}
      pagination={{ hideOnSinglePage: true, pageSize: 100 }}
      dataSource={dataSource}
    />
  );
};

export default withTranslation()(observer(ViewGeneratorDataGridCompareComponent));
