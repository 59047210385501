import React from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import { Tag, Popover, Select, Spin } from "antd";
import { DownOutlined } from "@ant-design/icons";
import BackendService from "./BackendService";
import GraphQlService from "./GraphQlService";

class AssignedUser extends React.Component {
  graphApi = new BackendService(`graphql`);
  commonApi = new BackendService(`common`);
  graphQlService = new GraphQlService();

  state = {
    isLoading: false,
    user: undefined,
    users: [],
  };

  componentDidMount() {
    if (this.props.user) this.setState({ user: this.props.user });
    else this.loadCurrentAssigned();
  }

  loadUsers = () => {
    this.setState({ isLoading: true });
    this.graphQlService.get(`{ users { id username }}`).then((r) => {
      this.setState({ isLoading: false, users: r.data.users });
    });
  };

  loadCurrentAssigned = () => {
    this.setState({ isLoading: true });
    this.graphQlService.get(`{containers(where:{path:"RootId",comparison:EQUAL,value:"${this.props.objectId}"}){assignedTo{id,username}}}`).then((r) => {
      this.setState({ isLoading: false, user: r.data.containers[0].assignedTo });
    });
  };

  assign = (userId) => {
    this.setState({ isLoading: true });
    this.graphQlService.client().mutate({
      mutation: this.graphQlService.stringToGql(`mutation mutate($objectType: String, $objectId: Guid, $userId: Guid) { userAssignToObject(objectType:$objectType, objectId:$objectId, userId:$userId) }`),
      variables: { objectType: this.props.objectType, objectId: this.props.objectId, userId: userId },
    }).then(() => {
      this.setState({ isLoading: false, user: this.state.users.filter((x) => x.id === userId)[0] });
    });
  }

  render() {
    return (
      <Popover
        trigger="click"
        title={this.props.t("assignedUser.assignUser")}
        placement={this.props.placement}
        onOpenChange={() => this.loadUsers()}
        content={
          <Select showSearch placeholder={this.props.t("assignedUser.selectUsers")} loading={this.state.isLoading} onChange={(e) => this.assign(e)} style={{ width: "100%" }} optionFilterProp="children" filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }>
            {this.state.users.map((d) => (
              <Select.Option key={d.id}>{d.username}</Select.Option>
            ))}
          </Select>
        }
      >
        <Spin spinning={this.state.isLoading}>
          <Tag>
            {this.state.user && (
              <span>
               {this.props.t("assignedUser.owner")} {this.state.user.username} <DownOutlined />
              </span>
            )}
            {!this.state.user && (
              <span>
                {this.props.t("assignedUser.assignOwner")} <DownOutlined />
              </span>
            )}
          </Tag>
        </Spin>
      </Popover>
    );
  }
}

export default withTranslation()(observer(AssignedUser));
