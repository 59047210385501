import { observer } from "mobx-react-lite";
import { withTranslation } from "react-i18next";
import { Col, Row, Tag, List, Divider, Button } from "antd";

import { CommentComponentButtons } from "./CommentComponentButtons";
import CommentComponentInput from "./CommentComponentInput";
import { useState } from "react";
import { EllipsisOutlined, EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const CommentComponentMainColumn = ({ comment, t, reload, simpleView = true }) => {

    const [editMode, setEditMode] = useState({});
    const [replyMode, setReply] = useState({});

    const getContent = (c) => {
        if (editMode?.id && c?.id === editMode?.id)
            return <CommentComponentInput reload={reload} comment={c} editMode={editMode} setEditMode={setEditMode} setReply={setReply} />

        return (
            <div>
                <div dangerouslySetInnerHTML={{ __html: c.content && c.content.replaceAll("\n", "<br/>") }}></div>
                {c.payload && c.payload.Files && c.payload.Files.length}
                {c.payload && c.payload.Files && (
                    <List
                        size="small"
                        bordered
                        header={t(`comment.attachments`)}
                        dataSource={c.payload.Files}
                        renderItem={(item) => (
                            <List.Item>
                                <a download={item.Filename} href={item.FileBase64}>
                                    {item.Filename}
                                </a>
                            </List.Item>
                        )}
                    />
                )}
            </div>
        );
    };

    return <Col span={22}>
        <Row style={{ marginTop: 5, marginBottom: 5 }}>
            <h4 style={{ marginBottom: 0 }}>{comment?.payload?.RaisedBy ? comment?.payload?.RaisedBy : comment.payload?.Responder ? comment.payload?.Responder : comment.createdBy?.username}</h4>
            {comment.payload?.Owner && (
                <Tag color="geekblue" style={{ marginLeft: 10 }}>
                    {t("comment.responseOwner")} {comment.payload?.Owner}
                </Tag>
            )}
            {comment.payload?.Deadline && (
                <Tag color="geekblue" style={{ marginLeft: 10 }}>
                    {t("comment.responseDeadline")} {comment.payload?.Deadline}
                </Tag>
            )}
        </Row>
        <Row>{getContent(comment)}</Row>
        {replyMode?.isVisible && replyMode?.isVisible === comment.id && (
            <Row style={{ marginTop: "20px" }}>
                <CommentComponentInput reload={reload} comment={comment} setReply={setReply} editMode={editMode} setEditMode={setEditMode} />
            </Row>
        )}
        <div style={{ marginTop: "20px", textAlign: 'left' }}>
            <CommentComponentButtons comment={comment} t={t}
                hideLabel={false} 
                editMode={editMode} setEditMode={setEditMode} setReply={setReply} 
                reload={reload} simpleView={simpleView}/>
        </div>
    </Col>;
}

export default withTranslation()(observer(CommentComponentMainColumn));