import React from "react";
import { Tag } from "antd";
import { withTranslation } from "react-i18next";
import { CheckCircleOutlined, ExclamationCircleOutlined, QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons";

export const hexToRGB = (hex, alpha = 1) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(
  ${parseInt(result[1], 16)},
    ${parseInt(result[2], 16)},
    ${parseInt(result[3], 16)},
      ${alpha})`
    : null;
};

const StatusIcon = ({ status }) => {
  if (!status || !status.name) return <></>;
  const style = { color: status.name?.includes("Completed") ? hexToRGB(status.color, 0.3) : status.color, fontSize: 24 };

  switch (status.icon) {
    case "exclamation-circle":
      return <ExclamationCircleOutlined style={style} />;
    case "warning":
      return <WarningOutlined style={style} />;
    case "question-circle":
      return <QuestionCircleOutlined style={style} />;
    default:
      return <CheckCircleOutlined style={style} />;
  }
};

const StatusTag = ({ status, t }) => {
  if (!status || !status.name) return "-";

  return (
    <div style={{ fontSize: 14, display: "inline-block" }}>
      <StatusIcon status={status} />
      <Tag
        color={status.name?.includes("Completed") || status.name?.includes("Unspecified") ? hexToRGB(status.color, 0.3) : status.color}
        style={{
          width: 100,
          textAlign: "center",
          borderRadius: 14,
          marginLeft: 10,
          padding: "0px 7px",
          position: "relative",
          top: -5,
        }}
      >
        {t(status.name)}
      </Tag>
    </div>
  );
};

export default withTranslation()(StatusTag);
