import { message } from "antd";
import * as _ from "lodash";
import { makeAutoObservable, toJS } from "mobx";
import dayjs from 'dayjs';
import BackendService from "../../Common/BackendService";
import GraphQlService from "../../Common/GraphQlService";
import { forkJoin } from "rxjs";

class DocumentsStore {
  graphqlApi = new BackendService("graphql");
  containersApi = new BackendService("containers");
  relationshipApi = new BackendService("relationships");
  graphQlService = new GraphQlService();

  documents = [];
  statistics = {
    totalDocuments: 0,
    status: {},
    lastVersionDistribution: {},
    referenceDistribution: {},
    riskStatusDistribution: {},
    byTypeByYearAndWeek: {
      values: [],
    },
  };
  showNewDocumentModal = false;
  showEditModal = false;
  editModalData;

  searchComponentJqlQuery = undefined;
  documentsGrouped = [];

  keys = {};

  dxf = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  addToDocuments(document) {
    return new Promise((resolve, reject) => {
      _.forEach(Object.keys(document.payload), (key) => {
        if (_.isUndefined(document.payload[key])) document.payload[key] = "";
      });
      this.containersApi
        .post(``, {
          name: document.name,
          owner: document.owners,
          type: "document",
          PayloadObject: document.payload,
          rootId: document.rootId,
        })
        .then((r) => {
          this.documents.unshift(document);
          resolve();
        });
    });
  }

  removeDocument(document, removeDocMesssage) {
    this.containersApi.delete(document.id).then((r) => {
      this.documents = this.documents.filter((x) => x.id !== document.id);
      message.success(removeDocMesssage);
    });
  }

  setStatistics(documents) {
    var newStatistics = {};
    newStatistics.totalDocuments = _(documents)
      // .filter(x => x.doc)
      .groupBy((doc) => doc.payload.Id.substr(0, doc.payload.Id.lastIndexOf("_")))
      .toPairs()
      .value().length;
    var ids = [];
    newStatistics.status = _.countBy(
      _(documents)
        .filter((x) => x.payload)
        .groupBy((doc) => doc.payload.Id.substr(0, doc.payload.Id.lastIndexOf("_")))
        .map((value, key) => {
          var docs = value.filter((x) => x.payload.Version.includes(".0"));
          var docsOrdered = _.orderBy(docs, (doc) => parseFloat(doc.payload.Version));
          if (docsOrdered.length > 0) {
            var lastDoc = _.last(docsOrdered);
            if (lastDoc.payload.VisaEngage === "ACC") ids.push(lastDoc.id);
            if (docsOrdered.length > 1 && docsOrdered[docsOrdered.length - 2].payload.VisaEngage === "REF" && lastDoc.payload.VisaEngage === "") {
              lastDoc.payload.VisaEngage = "REF-Ongoing";
            } else if (!_.isUndefined(lastDoc) && !!lastDoc.payload && lastDoc.payload.VisaEngage === "") {
              lastDoc.payload.VisaEngage = "Ongoing";
            }
            return {
              key: key,
              ...lastDoc,
            };
          }
          return undefined;
        })
        .filter((x) => x)
        .filter((x) => !!x.payload)
        .filter((x) => ["ACC", "COM", "REF", "Ongoing", "REF-Ongoing"].includes(x.payload.VisaEngage))
        .value(),
      "payload.VisaEngage"
    );
    newStatistics.lastVersionDistribution = _.countBy(
      documents.filter((x) => x.payload.LastVersion !== undefined),
      (v) => v["payload"]["LastVersion"].toUpperCase()
    );
    newStatistics.documentsReceivedByType = _(documents)
      .filter((doc) => !!doc.payload && doc.payload.DateEnvoiBAM_VFR != null && doc.payload.DateEnvoiBAM_VFR !== "" && !doc.payload.DateEnvoiBAM_VFR.includes("0001"))
      .map((doc) => {
        var startDate = doc.payload.DateEnvoiBAM_VFR.includes("/") ? dayjs(doc.payload.DateEnvoiBAM_VFR.substr(0, 10), "DD/MM/YYYY") : dayjs(doc.payload.DateEnvoiBAM_VFR);
        return {
          A: startDate.format("YYYY/MM"),
          type: doc.payload.Ref_Type,
        };
      })
      .countBy((doc) => doc.type)
      .value();

    let documentsGrouped = this.documentsGrouped;
    let documentsByLastVersion = documentsGrouped.map((x) => _.last(x.docs));

    newStatistics.referenceDistribution = {
      Concerns: _.countBy(documents, "payload.Concerns"),
      Ref_Issuer: _.countBy(documents, "payload.Ref_Issuer"),
      SGTI: _.countBy(documents, "payload.SGTI"),
      Ref_Type: _.countBy(documents, "payload.Ref_Type"),
      Ref_Family: _.countBy(documentsByLastVersion, "payload.Ref_Family"),
      Ref_Number: _.countBy(documents, "payload.Ref_Number"),
      Ref_DisciProjetine: _.countBy(documents, "payload.Ref_DisciProjetine"),
      Phase: _.countBy(documents, "payload.Phase"),
      TenderBatch: _.countBy(documents, "payload.TenderBatch"),
      QualityGrade: _.countBy(documents, "payload.QualityGrade"),
      PBS: _.countBy(documents, "payload.PBS"),
      Subcontractors: _.countBy(documents, "payload.Subcontractor.Name"),

      Version: _(documents).countBy("payload.Version").toPairs().sortBy(0).fromPairs().value(),
      VersionByType: _(documents)
        .groupBy("payload.Ref_Type")
        .map((value, key) => {
          return {
            groupName: key === "" ? "(empty)" : key,
            ..._(value).countBy("payload.Version").toPairs().sortBy(0).fromPairs().value(),
          };
        })
        .value(),
    };
    newStatistics.riskStatusDistribution = _.countBy(documentsByLastVersion, (doc) => doc.riskStatus?.name);
    newStatistics.expectedIterations = _(documents)
      .filter((x) => x.payload)
      .map((document) => {
        return {
          Id: document.payload.Id,
          Version: parseFloat(document.payload.Version || 0),
        };
      })
      .groupBy((document) => document.Id.substr(0, 9))
      .map((value, key) => {
        let out = [key];
        out.push(_.meanBy(value, (v) => v.Version));
        return out;
      })
      .fromPairs()
      .value();

    newStatistics.expectedDuration = _(documents)
      .map((document) => {
        return {
          Id: document.payload.Id,
          from: document.payload.DateEnvoiBAM_VFR,
          to: document.payload.VisaDate,
        };
      })
      .filter((document) => document.from !== "")
      .filter((document) => document.to !== "")
      .filter((document) => !_.isUndefined(document.from))
      .filter((document) => !_.isUndefined(document.to))
      .map((document) => {
        const f = dayjs(document.from.substr(0, 10), "DD/MM/YYYY");
        const t = dayjs(document.to.substr(0, 10), "DD/MM/YYYY");
        return {
          ...document,
          duration: Math.abs(f.diff(t, "days")),
        };
      })
      .filter((doc) => !_.isNaN(doc.duration))
      .filter((document) => document.duration < 300)
      .groupBy((document) => document.Id.substr(0, 9))
      .map((value, key) => {
        let out = [key];
        out.push(_.meanBy(value, (v) => v.duration));
        return out;
      })
      .fromPairs()
      .value();

    newStatistics.lastVersionDocumentsByVersion = this.sortObjectByKeys(
      _.fromPairs(
        _.map(
          _.groupBy(
            this.documents.filter((x) => (x.payload.LastVersion || "") === "Y"),
            "payload.Version"
          ),
          (values, key) => {
            let out = [key];
            out.push(values.length);
            return out;
          }
        )
      )
    );

    this.statistics = { ...this.statistics, ...newStatistics };
  }

  setKeys() {
    var newKeys = {};
    _(this.statistics.referenceDistribution)
      .keys()
      .forEach((key) => {
        newKeys[key] = Object.keys(this.statistics.referenceDistribution[key]).filter((v) => v !== "");
      });
    this.keys = newKeys;
  }

  addRelationship = (fromDocumentId, toTaskId) => {
    this.loading = true;
    this.relationshipApi.post("", { fromId: fromDocumentId, fromType: 500, toId: toTaskId, toType: 500 }).then(() => {
      this.loading = false;
    });
  };

  removeRelationship(fromDocumentId, toTaskId) {
    this.loading = true;
    this.relationshipApi.delete(`${fromDocumentId}/${toTaskId}`).then(() => {
      this.loading = false;
    });
  }

  sortObjectByKeys(obj) {
    var o = {};
    Object.keys(obj)
      .sort()
      .forEach((key) => (o[key] = obj[key]));
    return o;
  }

  loading = true;
  filterType = 0;
  filterValue;

  // page = 1;
  pageSize = 40;

  // totalEntries = 0;

  searchByJql(body) {
    this.loading = true;
    const jql = JSON.stringify(body).replaceAll('"', '\\"');
    this.graphQlService
      .client()
      .query({
        query: this.graphQlService.stringToGql(
          `{ containers(where: [{ path: "Type" comparison: EQUAL value: "document" }], jqlAssetType: "document", jql: "${jql}") {id,rootId,payload,isDeleted,version,type,name,rootId,createdDateTime,riskStatus{icon,name,color,statusId}} }`
        ),
      })
      .then((r) => {
        this.filterType = 2;
        this.filterValue = body;
        if (!r.data.containers) return;
        // this.totalEntries = r.data.totalEntries;
        this.processDocuments(_.cloneDeep(r.data.containers));
      });
  }

  searchByValue(value) {
    this.loading = true;
    this.containersApi.get(`search/document?value=${encodeURIComponent(value)}`).then((data) => {
      this.filterType = 1;
      this.filterValue = value;

      this.processDocuments(data);
    });
  }

  getConnectedTasks(documentRootId) {
    const query = `?query={containers(where:{path: "rootId", comparison: EQUAL,value:"${documentRootId}"}){tasks:parentOf{id,type,name,payload,rootId}}}`;
    return this.graphqlApi.get(query).then((data) => {
      return this.graphqlApi.deserializePayload(data.data["containers"][0]["tasks"]);
    });
  }

  async getDxf(documentRootId) {
    const b64toBlob = (b64Data, contentType = "application/dxf", sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });

      return blob;
    };

    const res = await this.graphQlService.get(`{ containers(where:{path:"RootId",comparison:EQUAL,value:"${documentRootId}"}){ dxf }}`);

    if (res.data.containers[0]?.dxf) {
      const data = JSON.parse(res.data.containers[0].dxf);

      return { FileName: data.FileName, Blob: b64toBlob(data.FileBase64.slice(37, data.FileBase64.length)) };
    }
  }

  loadDefault() {
    if (localStorage.getItem(`plan`) === "bas") return;

    this.loading = true;
    //page: ${this.page}, pageSize: ${this.pageSize}
    this.graphQlService.get(`{containers(where: { path: "Type", comparison: EQUAL, value: "document" }){id,rootId,payload,name,rootId,createdDateTime,riskStatus{icon,name,color,statusId,order}}}`).then((r) => {
      this.filterType = 0;
      if (!r.data.containers) return;
      // this.totalEntries = r.data.totalEntries;
      this.processDocuments(_.cloneDeep(r.data.containers));
    });
  }

  loadAdditinalData = (row) => {
    this.documentsGrouped.find((x) => x.Id === row.Id).isLoading = true;
    forkJoin(row.docs.map((doc) => this.graphqlApi.get(`?query={containers(where:{path:"rootId",comparison:EQUAL,value:%22${doc.rootId}%22}){rootId,evidences}}`))).subscribe((r) => {
      r.forEach((doc) => {
        var evidences = doc.data.containers[0].evidences;
        if (!evidences) evidences = "{}";
        var item =this.documentsGrouped.find((x) => x.Id === row.Id).docs.find((x) => x.rootId === doc.data.containers[0].rootId); 
        item.evidences = JSON.parse(evidences);
      });
      this.documentsGrouped.find((x) => x.Id === row.Id).isLoading = false;
    });
  };

  processDocuments = (containers) => {
    if (containers.length > 0) {
      this.documents = this.graphqlApi.deserializePayload(containers);
    } else this.documents = [];

    this.documentsGrouped = _(this.documents)
      .filter((x) => x.payload)
      .groupBy((doc) => doc.payload.Id.substr(0, doc.payload.Id.lastIndexOf("_")))
      .map((value, key) => {
        var docs = _.orderBy(value, (doc) => parseFloat(doc.payload.Version));
        var lastDoc = _.last(docs);
        return {
          Id: key,
          docs: docs,
          lastDoc: lastDoc,
          isCompleted: lastDoc && lastDoc.riskStatus && (lastDoc.riskStatus.name || "").toLowerCase().includes("completed") ? true : false,
        };
      })
      .value();

    this.setStatistics(toJS(this.documents));
    this.setKeys();
    this.loading = false;
  };
}

export default new DocumentsStore();
