import { makeAutoObservable, toJS } from "mobx";
import GraphQlService from "../GraphQlService";
import i18next from "i18next";
import { message } from "antd";
import { cloneDeep } from "lodash";

class CommentsStore {
  isLoading = false;

  allComments = [];
  comments = [];
  expandedKeys = [];

  isManagement = false;

  graphQlService = new GraphQlService();

  pageNumber = 1;
  pageSize = 10;

  sortBy = null;
  sortOrder = null;

  totalEntries = 0;

  currectConnectedDocument = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setData = data => {
    this.expandedKeys = [];
    this.currectConnectedDocument = undefined;

    this.allComments = cloneDeep(data.list).map(x => ({
      ...x,
      children: !x.children || x.children.length === 0 ? undefined : x.children,
      payload: typeof x?.payload === "string" ? JSON.parse(x.payload) : x?.payload
    }));
    this.comments = this.allComments;
    this.totalEntries = data.totalEntries;
    this.isLoading = false;
  };

  updateAfterLoadMore = (list, comment) => {
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === comment.id) list[i] = comment;
      if (list[i].children) this.updateAfterLoadMore(list[i].children, comment);
    }
  };

  update = ({ id, content, file, owner, deadline }) => {
    this.isLoading = true;
    return this.graphQlService
      .post(`mutation mutate($data: UpdateCommentCommand){ commentUpdate(data: $data) }`, {
        data: { id: id, content: content, owner: owner, deadline: deadline, file }
      })
      .then(r => {
        this.isLoading = false;
        if (r.errors) this.graphQlService.displayErrors(r.errors);
        else message.success(i18next.t("comment.commentModified"));
      });
  };

  delete = id => {
    this.isLoading = true;
    return this.graphQlService
      .post(`mutation mutate($data: Guid){ commentDelete(id: $data) }`, { data: id })
      .then(r => {
        this.isLoading = false;
        if (r.errors) this.graphQlService.displayErrors(r.errors);
        else message.success(i18next.t("comment.commentDeleted"));
      });
  };

  reply = (content, owner, deadline, parentId, containerId) => {
    this.isLoading = true;

    let data = { content: content, payload: JSON.stringify({ Deadline: deadline, Owner: owner }) };
    if (parentId) {
      data.targetObjectType = "Comments";
      data.targetObjectId = parentId;
    } else if (containerId) {
      data.mainParentType = "Container";
      data.mainParentId = containerId;
    }
    return this.graphQlService
      .post(`mutation mutate($data: CreateCommentCommand){ commentCreate(data: $data) }`, { data: data })
      .then(r => {
        this.isLoading = false;
        if (r.errors) this.graphQlService.displayErrors(r.errors);
        else message.success(i18next.t("comment.commentPosted"));
      });
  };

  resolve = ids => {
    this.isLoading = true;
    return this.graphQlService
      .post(`mutation mutate($data: ResolveCommentCommand){ commentResolve(data: $data) }`, {
        data: { commentIds: ids }
      })
      .then(r => {
        this.isLoading = false;
        if (r.errors) this.graphQlService.displayErrors(r.errors);
        else message.success(i18next.t("comment.commentResolved"));
      });
  };
}

export default new CommentsStore();
