import { CommentOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, EyeOutlined, SaveOutlined, SendOutlined, UploadOutlined } from "@ant-design/icons";
import React from "react";
import { Avatar, Button, Card, Comment, Divider, Form, Input, List, Tooltip, Upload, Select, DatePicker, Tag, Row, Col } from "antd";
import CommentsStore from "./CommentsStore";
import { Link } from "react-router-dom";

export const CommentComponentButtons = ({ comment, t, hideLabel, editMode, setEditMode, setReply, reload, simpleView, setCurrentDocument, showDetailsDrawer }) => {
    const custom = async (id, e) => {
        CommentsStore.isLoading = true;
        var base64File = await toBase64(e.file);
        e.onSuccess(base64File);

        CommentsStore
            .update({ id: id, file: JSON.stringify({ name: e.file.name, base64: base64File, contentType: e.file.type }) })
            .then(() => {
                reload();
            });
    };

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    return <Row justify="start">
        {(!simpleView && comment.mainParent) &&
            <Col style={{ marginRight: 6 }}>
                <Link style={{ padding: 0, margin: 0, cursor: "pointer" }}
                    to={`/${localStorage.getItem(
                        `projectName`
                    )}/documents?query={"id":"9b99a9a8-0123-4456-b89a-b183c198580f","type":"group","children1":[{"type":"rule","id":"8a8bb89a-0123-4456-b89a-b183c198766d","properties":{"field":"Name","operator":"equal","value":["${encodeURIComponent(
                        comment.mainParent.name
                    )}"],"valueSrc":["value"],"valueType":["text"]}}]}`}
                >
                    <Button style={{ padding: 0, margin: 0, cursor: "pointer" }} size="small" type="text" icon={<EyeOutlined />}>
                        <small>{hideLabel && t("collect.view")}</small>
                        {!hideLabel && t("collect.view")}
                    </Button>
                </Link>
            </Col>}

        <Col style={{ marginRight: 6 }}>
            <Button style={{ padding: 0, margin: 0, cursor: "pointer" }} size="small" icon={<CommentOutlined />} type="text" key="comment-reply-to"
                onClick={() => setReply((prev) => ({ ...prev, isVisible: prev.isVisible ? undefined : comment.id }))}
            >
                <small>{hideLabel && t("comment.reply")}</small>
                {!hideLabel && t("comment.reply")}
            </Button>
        </Col>

        <Col style={{ marginRight: 6 }}>
            <Button style={{ padding: 0, margin: 0 }} size="small" type="text" icon={<EditOutlined />}
                onClick={() => {
                    if (editMode.id && editMode.id === comment.id) setEditMode((e) => ({ id: undefined, content: comment.content }));
                    else setEditMode((e) => ({ id: comment.id, content: comment.content }));
                }}
            >
                <small>{hideLabel && t("comment.modify")}</small>
                {!hideLabel && t("comment.modify")}
            </Button>
        </Col>
        <Col style={{ marginRight: 6 }}>
            <Upload
                {...{
                    name: "file",
                    multiple: false,
                    showUploadList: false,
                    customRequest: (e) => custom(comment.id, e),
                }}
            >
                <span style={{ margin: "auto", cursor: "pointer" }}>
                    <Button style={{ padding: 0, margin: 0 }} size="small" icon={<UploadOutlined />} type="text">
                        <small >{hideLabel && t("comment.uploadFile")}</small>
                        {!hideLabel && t("comment.uploadFile")}
                    </Button>
                </span>
            </Upload>
        </Col>
        {comment.createdBy?.id === localStorage.getItem(`id`) &&
            <Col style={{ marginRight: 6 }}>
                <Button style={{ padding: 0, margin: 0, color: "red" }} size="small" icon={<DeleteOutlined />} type="text"
                    onClick={() => {
                        CommentsStore.delete(comment.id).then(() => reload());
                    }}>
                    <small>{hideLabel && t("comment.delete")}</small>
                    {!hideLabel && t("comment.delete")}
                </Button>
            </Col>}


        {(!simpleView) &&
            <Col>
                <Button
                    size="small"
                    icon={<EllipsisOutlined />}
                    style={{ padding: 0 }}
                    type="text"
                    onClick={() => CommentsStore.currectConnectedDocument = comment}
                >
                    <small>{hideLabel && t("comment.moreInfo")}</small>
                    {!hideLabel && t("comment.moreInfo")}
                </Button>
            </Col>}
    </Row >
};