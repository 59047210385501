import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const useLanguageEffect = (callback, dependencies = []) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    callback();
  }, [i18n.language, ...dependencies]);
};

export default useLanguageEffect;
