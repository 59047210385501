import { withTranslation } from "react-i18next";
import CommentsStore from "./CommentsStore";
import { observer } from "mobx-react";
import { Spin, Table } from "antd";
import { NameAvatarComponent } from "../Users/NameAvatarComponent";
import { useEffect, useState } from "react";
import CommentComponentInput from "./CommentComponentInput";
import CommentComponentMainColumn from "./CommentComponentMainColumn";
import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import GraphQlService from "../GraphQlService";
import dayjs from "dayjs";
import ProjectStore from "../../Settings/Projects/ProjectStore";
import StatusTag from "../../components/StatusTag";
import { themeConfig } from "../Layout/config";

const CommentsComponent = ({ canAddNew = false, t, containerId, reload, simpleView = true }) => {
  const [hoverRecordId, setHoverRecordId] = useState();
  const { currentTheme } = themeConfig();

  useEffect(() => {}, [CommentsStore.comments, CommentsStore.expandedKeys]);
  useEffect(() => {
    ProjectStore.load(localStorage.getItem(`projectId`));
  }, []);

  let columns = [
    {
      width: 50,
      title: " "
    },
    {
      title: t("comment.author"),
      width: 50,
      render: (_v, r) => (
        <NameAvatarComponent
          name={
            r.payload?.RaisedBy
              ? r.payload?.RaisedBy
              : r.payload?.Responder
              ? r.payload?.Responder
              : r.createdBy?.username
          }
        />
      )
    },
    {
      title: t("comment.comment"),
      width: 650,
      render: (_v, comment) => (
        <CommentComponentMainColumn
          reload={reload}
          t={t}
          simpleView={simpleView}
          comment={comment}
          key={`com_${comment.id}`}
        />
      )
    }
  ];
  if (!simpleView) {
    columns = [
      ...columns,
      {
        title: "Document",
        width: 260,
        render: (_v, r) => <span style={{ fontSize: 12 }}>{r.mainParent ? r.mainParent.name : " - "}</span>
      },
      {
        title: "Assurance Package",
        width: 150,
        render: (_v, r) => (
          <span style={{ fontSize: 12 }}>{r.payload?.AssurancePackage ? r.payload.AssurancePackage : " - "}</span>
        )
      },
      {
        key: "CreatedDateTime",
        title: t("comment.createdAt"),
        width: 140,
        render: (_v, r) => (
          <span style={{ fontSize: 12 }}>
            {dayjs(r.payload?.DateRaised ? r.payload?.DateRaised : r.createdDateTime)?.format("MMMM Do YYYY")}
          </span>
        ),
        // defaultSortOrder: "descend",
        // sorter: (a, b) => new Date(a.createdDateTime) - new Date(b.createdDateTime),
        sorter: true
      },
      {
        title: "Due Date",
        width: 140,
        render: (_v, r) => (
          <span style={{ fontSize: 12 }}>
            {r.payload?.DueDateForResponse
              ? dayjs(r.payload?.DueDateForResponse)?.format("MMMM Do YYYY")
              : r.payload?.TaskBaselineStart
              ? dayjs(r.payload?.TaskBaselineStart).format("MMMM Do YYYY")
              : "-"}
          </span>
        )
      },
      {
        key: "Payload.RiskStatus.Order",
        width: 150,
        align: "left",
        title: t("documents.titles.riskStatus"),
        sorter: true,
        render: record => (
          <>{record.riskStatus && record.riskStatus.name && <StatusTag status={record.riskStatus} t={t} />}</>
        ),
        width: 180
      }
    ];
  }

  return (
    <>
      <Table
        dataSource={CommentsStore.comments}
        columns={columns}
        size="small"
        className={currentTheme === "light" ? "ant-table-beawre-comments" : "ant-table-beawre-dark"}
        rowClassName={r => {
          if (r.parentId && !hoverRecordId) return "comment-row-color";
          return "comment-row-colorwhite";
        }}
        rowKey={record => `comment_${record.id}`}
        onRow={record => {
          return {
            onMouseEnter: () => setHoverRecordId(record.Id),
            onMouseLeave: () => setHoverRecordId(undefined)
          };
        }}
        expandable={{
          onExpand: async (ex, comment) => {
            if (!comment.children) return;
            if (!ex) {
              CommentsStore.expandedKeys = CommentsStore.expandedKeys.filter(x => x.key !== `comment_${comment.id}`);
              return;
            }
            comment.isLoading = true;

            var graphQlService = new GraphQlService();

            var tasks = comment.children.map(child =>
              graphQlService
                .get(
                  `{ comment(id: "${child.id}") { id content isResolved parentId createdDateTime createdBy { id username } payload children { id } } }`
                )
                .then(r => {
                  var x = r.data.comment;
                  return {
                    ...x,
                    children: !x.children || x.children.length === 0 ? undefined : x.children,
                    payload: typeof x?.payload === "string" ? JSON.parse(x.payload) : x?.payload
                  };
                })
            );
            var values = await Promise.all(tasks);

            for (let i = 0; i < comment.children.length; i++) {
              comment.children[i] = values.find(x => x.id === comment.children[i].id);
              CommentsStore.updateAfterLoadMore(CommentsStore.comments, comment.children[i]);
              CommentsStore.updateAfterLoadMore(CommentsStore.allComments, comment.children[i]);
            }
            comment.isLoading = false;

            CommentsStore.expandedKeys.push({ key: `comment_${comment.id}` });
            CommentsStore.expandedKeys = CommentsStore.expandedKeys.map(x => ({ ...x, time: Date.now() }));
            CommentsStore.comments = CommentsStore.comments.map(x => ({ ...x, time: Date.now() }));
          },
          expandedRowKeys: CommentsStore.expandedKeys.map(x => x.key),
          expandIcon: ({ expanded, onExpand, record }) => {
            if (!record.children) return undefined;
            if (record.isLoading === true) return <Spin />;
            if (!expanded) return <PlusSquareOutlined onClick={e => onExpand(record, e)} />;
            if (expanded) return <MinusSquareOutlined onClick={e => onExpand(record, e)} />;
          }
        }}
        locale={{
          triggerDesc: t("comment.descending"),
          triggerAsc: t("comment.ascending"),
          cancelSort: t("comment.cancelSort")
        }}
        footer={() => <div>{canAddNew && <CommentComponentInput reload={reload} containerId={containerId} />}</div>}
        pagination={{
          total: CommentsStore.totalEntries,
          pageSize: CommentsStore.pageSize,
          current: CommentsStore.pageNumber
        }}
        onChange={(p, f, s, e) => {
          CommentsStore.pageNumber = p.current;
          CommentsStore.pageSize = p.pageSize;

          CommentsStore.sortBy = `"${s.columnKey}"`;
          if (s.order) CommentsStore.sortOrder = `"${s.order.substring(0, 3)}"`;
          else CommentsStore.sortOrder = null;

          reload();
        }}
      />
    </>
  );
};

export default withTranslation()(observer(CommentsComponent));
