import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { observer } from "mobx-react";

import { MoreOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Dropdown,
  Flex,
  Form,
  Input,
  List,
  message,
  Modal,
  Pagination,
  Popconfirm,
  Radio,
  Tooltip,
  Typography
} from "antd";

import { Comment } from "@ant-design/compatible";

import moment from "moment";

import ImageAnnotatorComponent from "../../../../Common/ImageAnnotator/ImageAnnotatorComponent";

import * as AntdIcons from "@ant-design/icons";

import { isEmpty } from "lodash";
import { withTranslation } from "react-i18next";
import ViewGeneratorStore from "../../../ViewGeneratorStore";

const statusesIcons = {
  check: <AntdIcons.QuestionCircleTwoTone twoToneColor="#cecece" />,
  on: <AntdIcons.CheckCircleTwoTone twoToneColor="#27ae60" />,
  err: <AntdIcons.CloseCircleTwoTone twoToneColor="#e74c3c" />,
  info: <AntdIcons.ExclamationCircleTwoTone twoToneColor="#f1c40f" />,
  notRequired: <AntdIcons.MinusCircleTwoTone twoToneColor={"#dddddd"} />
};

const isJson = value => {
  if (!value) {
    return false;
  }
  return value.startsWith("{") || value.startsWith("[");
};

export const FileApproveModal = withTranslation()(
  observer(({ cellData, t }) => {
    const [isVisible, setIsVisible] = useState(false);
    const approveFile = useMemo(() => cellData, [cellData]);

    const [status, setStatus] = useState(approveFile?.status);
    const [comments, setComments] = useState([]);
    const [annotationsAndClaims, setAnnotationsAndClaims] = useState({});
    const [currentAnnotations, setCurrentAnnotations] = useState([]);

    const changedRef = useRef(false);
    const changeCallback = () => (changedRef.current = true);

    const handleImageAnnotatorStatusChange = e => {
      setStatus(e.target.value);
      changeCallback();
    };

    const handleAnnotationsUpdate = e => {
      setAnnotationsAndClaims(e);
      setCurrentAnnotations(e?.annotations ?? []);
      changeCallback();
    };

    const observeSetComments = val => {
      setComments(val);
      changeCallback();
    };

    const onClose = () => {
      const payload = {
        ...(isJson(approveFile?.displayValue)
          ? JSON.parse(approveFile?.displayValue)
          : { url: approveFile.displayValue }),
        ...annotationsAndClaims,
        status,
        comments
      };

      if (changedRef.current)
        ViewGeneratorStore.updateContainerProperty(cellData.data.id, cellData.fieldId, JSON.stringify(payload));

      setIsVisible(false);

      changedRef.current = false;
    };

    const imageUrl = useMemo(() => {
      const imagePath = isJson(approveFile?.displayValue)
        ? JSON.parse(approveFile?.displayValue)?.url
        : approveFile?.displayValue;

      if (imagePath && isJson(imagePath)) {
        return imagePath;
      }
      return imagePath;
    }, [approveFile]);

    useEffect(() => {
      setCurrentAnnotations(
        isJson(approveFile?.displayValue) ? JSON.parse(approveFile?.displayValue)?.annotations ?? [] : []
      );
    }, []);

    useEffect(() => {
      if (isJson(approveFile?.displayValue)) {
        const values = JSON.parse(approveFile?.displayValue);

        if (values.hasOwnProperty("status")) {
          if (values.status !== "info") setStatus(values.status);
          else setStatus("err");
        } else {
          if (cellData?.status !== "info") setStatus(cellData?.status);
          else setStatus("err");
        }

        if (values.hasOwnProperty("comments")) setComments(values.comments);
        if (values.hasOwnProperty("annotations"))
          setAnnotationsAndClaims(prev => {
            prev["annotations"] = values.annotations;
            return prev;
          });
        if (values.hasOwnProperty("claims"))
          setAnnotationsAndClaims(prev => {
            prev["claims"] = values.claims;
            return prev;
          });
      }
    }, [approveFile]);

    const restoreNotRequired = () => {
      ViewGeneratorStore.updateContainerProperty(
        cellData.data.id,
        cellData.fieldId,
        JSON.stringify({ status: undefined })
      ).then(() => {
        ViewGeneratorStore.loadData();
      });
    };

    return (
      <>
        <span id="next">Next</span>

        <Button
          type="text"
          onClick={() => {
            setIsVisible(true);
            if (status === "notRequired") {
              restoreNotRequired();
            }
          }}
          style={{ zIndex: 100 }}
          icon={statusesIcons[status]}
        />

        {status !== "notRequired" && (
          <Modal
            open={isVisible}
            destroyOnClose
            closable
            onCancel={onClose}
            width="80%"
            title={
              <Flex horizontal gap="middle">
                <Radio.Group onChange={handleImageAnnotatorStatusChange} value={status}>
                  <Tooltip title={t("fileApprove.uploaded")}>
                    <Radio.Button value="check">
                      <AntdIcons.QuestionCircleTwoTone twoToneColor="#cecece" />
                    </Radio.Button>
                  </Tooltip>
                  <Tooltip title={t("fileApprove.approve")}>
                    <Radio.Button value="on">
                      <AntdIcons.CheckCircleTwoTone twoToneColor="#27ae60" />
                    </Radio.Button>
                  </Tooltip>

                  <Tooltip title={t("fileApprove.rejected")}>
                    <Radio.Button value="err">
                      <AntdIcons.CloseCircleTwoTone twoToneColor="#e74c3c" />
                    </Radio.Button>
                  </Tooltip>
                </Radio.Group>
              </Flex>
            }
            footer={null}
            // footer={
            //     <FileApproveModalFooter status={status} comments={comments} setComments={observeSetComments} />
            // }
          >
            <Flex horizontal style={{ minHeight: 300 }} justify="space-around">
              <div style={{ width: status === "err" ? "68%" : "100%" }}>
                <ImageAnnotatorComponent
                  submissionId={""}
                  url={imageUrl}
                  allowModify={true}
                  allowClaim={false}
                  currentAnnotations={currentAnnotations}
                  onAnnotationsUpdated={handleAnnotationsUpdate}
                />
              </div>
              <div style={{ width: status === "err" ? "28%" : 0 }}>
                <FileApproveModalFooter status={status} comments={comments} setComments={observeSetComments} />
              </div>
            </Flex>
          </Modal>
        )}
      </>
    );
  })
);

const FileApproveModalFooter = withTranslation()(({ status, comments, setComments, t }) => {
  const [form] = Form.useForm();
  const onFinish = values => {
    const date = new Date();

    if (!values.comment || isEmpty(values.comment)) return message.error(t("common.commentEmpty"));
    if (values.comment) {
      setComments(prev => [{ title: values.comment, author: localStorage.getItem(`username`), date }, ...prev]);

      // setComments(prev => [...prev, values.comment]);
      form.resetFields();
    }
  };

  const handleRemoveComment = index => {
    const newComments = [...comments];
    newComments.splice(index, 1);
    setComments(newComments);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 4;
  const currentComments = comments.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  const handlePageChange = page => {
    setCurrentPage(page);
  };
  const getMenu = index => [
    {
      label: (
        <Popconfirm
          title={t("collect.areYouSure")}
          okText={t("collect.yes")}
          cancelText={t("collect.no")}
          onConfirm={async () => {
            handleRemoveComment(index);
          }}
        >
          {t("collect.delete")}
        </Popconfirm>
      ),

      key: `1`,
      danger: true
    }
  ];

  const CommentComponent = ({ comment, index }) => (
    <Comment
      actions={[
        <Dropdown
          menu={{ items: getMenu(index) }}
          getPopupContainer={triggerNode => triggerNode.parentNode}
          placement="bottomRight"
        >
          <Button
            type="text"
            style={{ fontWeight: "400", position: "absolute", top: "1px", right: "10px", padding: 0 }}
          >
            <MoreOutlined
              style={{ fontSize: 18, position: "absolute", left: "100%", color: currentTheme === "dark" && "black" }}
            />
          </Button>
        </Dropdown>
      ]}
      author={<p>{comment?.author}</p>}
      content={<p>{comment?.title}</p>}
      datetime={
        <Tooltip title={moment(comment?.date).format("YYYY-MM-DD HH:mm:ss")}>
          <span style={{ marginRight: "3px" }}>{moment(comment?.date).fromNow()}</span>
        </Tooltip>
      }
    />
  );

  const renderItem = useCallback(
    (comment, index) => {
      return (
        <>
          <CommentComponent comment={comment} index={index} />
          <Divider />
        </>
      );
    },
    [comments]
  );

  const submitOnEnter = () => {
    form.submit();
  };

  if (status !== "err") return <></>;

  return (
    <Flex vertical style={{ width: "100%" }} gap={10}>
      <List
        bordered={false}
        header={
          <Flex justify="space-between" align="center">
            <Typography.Title level={5}>{t("documents.drawer.comments")}</Typography.Title>
          </Flex>
        }
        dataSource={currentComments}
        renderItem={renderItem}
        locale={{ emptyText: <></> }}
      />
      {comments.length > pageSize && (
        <Pagination
          align="center"
          current={currentPage}
          pageSize={pageSize}
          total={comments.length}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      )}
      <Flex style={{ width: "100%" }} vertical gap={10}>
        <Form onFinish={onFinish} form={form}>
          <Form.Item name="comment">
            <Input.TextArea
              onPressEnter={submitOnEnter}
              rows={2}
              autoSize={{ minRows: 2, maxRows: 2 }}
            ></Input.TextArea>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" style={{ width: "100%" }} type="primary">
              {t("viewBuilder.save")}
            </Button>
          </Form.Item>
        </Form>
      </Flex>
    </Flex>
  );
});
