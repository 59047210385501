import { Button, Comment, List, Form, Tag, Input, Select, DatePicker } from "antd";
import { NameAvatarComponent } from "../Users/NameAvatarComponent";
import { useEffect, useState } from "react";
import { CloseOutlined, SaveOutlined, SendOutlined } from "@ant-design/icons";
import CommentsStore from "./CommentsStore";
import { CommentComponentButtons } from "./CommentComponentButtons";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";
import ProjectStore from "../../Settings/Projects/ProjectStore";
import dayjs from "dayjs";

const CommentComponentInput = ({ t, reload, comment, setReply, editMode, setEditMode, containerId }) => {
  const [responseOwner, setResponseOwner] = useState("");
  const [responseDeadline, setResponseDeadline] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    if (editMode?.id) {
      setContent(editMode.content);
      comment.payload?.Owner && setResponseOwner(comment.payload?.Owner);
      comment.payload?.Deadline && setResponseDeadline(dayjs(comment.payload?.Deadline));
    } else {
      if (responseOwner) setResponseOwner("");
      if (responseDeadline) setResponseDeadline("");
    }
  }, [editMode]);

  return (
    <Form>
      <Form.Item>
        <Input.TextArea
          placeholder={t(`comment.replyToThisComment`)}
          value={content}
          autoSize={{ minRows: 2 }}
          onChange={e => {
            setContent(e.target.value);
          }}
        />
      </Form.Item>
      <Form.Item>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <h4 style={{ marginTop: 5, marginRight: 10 }}> {t("comment.response")}</h4>
          <Select
            style={{ marginRight: 10, width: 200 }}
            placeholder={t("comment.owner")}
            onChange={value => {
              if (value) setResponseOwner(value);
            }}
            value={responseOwner}
            options={ProjectStore.project?.users?.map(user => ({
              value: user.user.email,
              label: user.user.username
            }))}
          />
          <DatePicker
            style={{ marginRight: 10, width: 200, height: 32 }}
            placeholder={t("comment.deadline")}
            value={responseDeadline}
            onChange={value => {
              if (value) setResponseDeadline(value);
            }}
            size="small"
          />
          <Button.Group>
            <Button
              icon={<CloseOutlined />}
              type="default"
              onClick={() => {
                if (setEditMode) setEditMode({ id: undefined });
                if (setReply) setReply(() => ({ isVisible: false }));
              }}
            />
            <Button
              icon={<SendOutlined />}
              key="3"
              type="primary"
              onClick={() => {
                // = = = is modify
                if (editMode?.id) {
                  CommentsStore.update({ ...editMode, content, owner: responseOwner, deadline: responseDeadline }).then(
                    () => {
                      setEditMode({});
                      if (reload) reload();
                    }
                  );
                } else {
                  // = = = New comment
                  CommentsStore.reply(content, responseOwner, responseDeadline, comment?.id, containerId).then(() => {
                    if (setReply) setReply(p => ({ ...p, isVisible: false }));
                    if (reload) reload();
                  });
                }
              }}
            >
              {t(`comment.send`)}
            </Button>
          </Button.Group>
        </div>
      </Form.Item>
    </Form>
  );
};

export default withTranslation()(observer(CommentComponentInput));
