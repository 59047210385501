"use client";

import { ConfigProvider, Spin } from "antd";
import "antd/dist/reset.css";
import React, { Suspense } from "react";
import { CookiesProvider } from "react-cookie";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import i18n from "./components/i18n";
import registerServiceWorker from "./registerServiceWorker";

import config from "devextreme/core/config";
import { themeConfig } from "./Common/Layout/config";
import env from "./env.json";
import "./index.css";

import en from "antd/locale/en_GB";
import es from "antd/locale/es_ES";

import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";
// dayjs.extend(require('dayjs/plugin/isSameOrBefore'))

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

if (env.DevExtremeKey) {
  config({ licenseKey: env.DevExtremeKey });
}

const browserHistory = createBrowserHistory({ basename: "" });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    connectionString:
      "InstrumentationKey=60e94e44-79f2-4be8-8c45-0d8f6ac48de1;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});
appInsights.loadAppInsights();
registerServiceWorker();

const container = document.getElementById("root");
const root = createRoot(container);

const languages = {
  "en": en,
  "es": es
}

root.render(
  <Suspense
    fallback={
      <div style={{ marginTop: "20%" }}>
        <div style={{ textAlign: "center" }}>
          <img
            src={"./Beawre_login_logo.png"}
            alt="Beawre"
            style={{ height: "42px", marginBottom: 30, marginTop: 40 }}
          />
        </div>
        <Spin spinning={true} size="large">
          <div style={{ height: 50, width: 50 }}></div>
        </Spin>
      </div>
    }
  >
    {/* <AppInsightsErrorBoundary appInsights={reactPlugin}> */}
    <BrowserRouter basename={baseUrl}>
      <I18nextProvider i18n={i18n}>
        <CookiesProvider>
          <ConfigProvider theme={themeConfig()} locale={languages[localStorage.getItem(`language`) || 'en']}>
            <App />
          </ConfigProvider>
        </CookiesProvider>
      </I18nextProvider>
    </BrowserRouter>
    {/* </AppInsightsErrorBoundary> */}
  </Suspense>
);
