import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Col, Upload, message, List, Spin, Modal, ConfigProvider } from "antd";
import ConfigStore from "./../Common/ConfigStore";
import ForgeViewer from "react-forge-viewer";
import "./DocumentsManagementComponent.css";
import BackendService from "../Common/BackendService";
import GraphQlService from "../Common/GraphQlService";
import { v4 as uuidv4 } from "uuid";
import DocumentsStore from "../WorflowRiskControl/Documents/DocumentsStore";
import { t } from "i18next";
import { themeConfig } from "../Common/Layout/config";

export default class DocumentsManagmentComponent extends React.Component {
  forgeApi = new BackendService(`externals/forge`);
  containerApi = new BackendService(`containers`);
  appConfig = JSON.parse(localStorage.getItem("appConfig"));
  graphQlService = new GraphQlService();

  state = {
    documents: [],
    selectedDocument: null,
    isLoading: false,
    viewer: null,
    modal1: false,
    modal2: false,
    forge: undefined
  };

  componentDidMount() {
    this.loadDocuments();
    this.forgeApi.get(``).then(r => {
      this.setState({ forge: r });
    });
  }

  loadDocuments = async () => {
    this.setState({ isLoading: true });

    const appConfig = JSON.parse(localStorage.getItem("appConfig"));

    const res = await DocumentsStore.getDxf(this.props.containerId);

    const r = [];

    if (res) {
      r.push({
        id: uuidv4(),
        name: res.FileName,
        url: `${localStorage.getItem(`host`)}/utils/dxf/${this.props.containerId}`
      });
    } else if (appConfig.isDemo && appConfig.isDemo === true) {
      r.push({
        id: "01E7M6E4TGFXEK35KRKRHJALYYSAHDXP3N",
        name: "demoData.xlsx",
        url:
          "https://beawre.sharepoint.com/_layouts/15/Doc.aspx?sourcedoc=%7BADC82D66-51F5-4E54-902F-18900E3BBF6D%7D&file=demoData.xlsx&action=default&mobileredirect=true"
      });
      r.push({
        id: "01E7M6E4RI4PC3KBOBS5FY7NPQMCRJFVW3",
        name: "kamery.dwg",
        url: "https://beawre.sharepoint.com/vfrdemo/Containers/d9001898-2973-4f32-ab4c-6a4ec1ada80c/kamery.dwg"
      });
      r.push({
        id: "01E7M6E4VA6ARPQ6GZ6RC3FD2LRL46CP2W",
        name: "Simple-House-With-3-Bedrooms.dwg",
        url:
          "https://beawre.sharepoint.com/vfrdemo/Containers/d9001898-2973-4f32-ab4c-6a4ec1ada80c/Simple-House-With-3-Bedrooms.dwg"
      });
    }

    this.setState({ documents: r, isLoading: false });
  };

  toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  uploadDocument = async options => {
    const { onSuccess, onError, file, onProgress } = options;

    const base64File = await this.toBase64(file);

    this.graphQlService
      .post(
        `mutation mutate($containerId: Guid, $file: String){ uploadContainerFile(containerId: $containerId, file: $file) }`,
        {
          containerId: this.props.containerId,
          file: JSON.stringify({ FileName: file.name, FileBase64: base64File, ContentType: file.type })
        }
      )
      .then(res => {
        onSuccess("Ok");
      })
      .catch(err => {
        onError({ err });
      });
  };

  handleTokenRequested(onAccessToken) {
    // var token = {
    //   access_token: this.state.forge.token,
    //   expires_in: this.state.forge.expire,
    //   token_type: "Bearer",
    // };
    //
    onAccessToken(this.state.forge.access_token, this.state.forge.expires_in);
  }

  onChange = info => {
    const { status } = info.file;
    if (status !== "uploading") {
    }
    if (status === "done") {
      message.success(`${info.file.nam} ${t("uploaded")}`);

      this.loadDocuments();
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      this.loadDocuments();
    }
  };

  handleViewerError(error) {}

  /* after the viewer loads a document, we need to select which viewable to
  display in our component */
  handleDocumentLoaded3d(doc, viewables) {
    if (viewables.length === 0) {
      console.error("Document contains no viewables.");
    } else {
      //Select the first viewable in the list to use in our viewer component
      this.setState({ viewer: viewables[1] });
    }
  }
  handleDocumentLoaded2d(doc, viewables) {
    if (viewables.length === 0) {
      console.error("Document contains no viewables.");
    } else {
      //Select the first viewable in the list to use in our viewer component
      this.setState({ viewer: viewables[0] });
    }
  }

  handleDocumentError(viewer, error) {}

  handleModelLoaded(viewer, model) {}

  handleModelError(viewer, error) {}

  render() {
    const handleOnChange = options => {
      const { file, fileList, event } = options;
    };

    const props = {
      // name: "file",
      multiple: false,
      customRequest: options => this.uploadDocument(options),
      onChange: options => handleOnChange(options)
      // action: ConfigStore.backendUrl + "containers/documents",
      // headers: { Authorization: 'Bearer' + localStorage.getItem(`jwtToken`) },
      // data: { TargetType: "containers", TargetId: this.props.containerId },
      // onProgress({ percent }, file) {
      //
      // },
    };

    return (
      <div style={{ width: 950, padding: "0px !important", boxShadow: "none" }}>
        <h3 style={{ color: this.props.currentTheme === "dark" && "white" }}>{t("documents.documents")}</h3>
        <Spin tip={t("documents.loading")} spinning={this.state.isLoading}>
          <List
            size="small"
            bordered
            dataSource={this.state.documents}
            renderItem={item => (
              <List.Item
                style={{ cursor: "pointer" }}
                key={item.id}
                onClick={() => {
                  if (item.name.endsWith(".dwg")) {
                    if (item.name.startsWith("Simple")) {
                      this.setState({ modal2: true });
                    } else {
                      this.setState({ modal1: true });
                    }
                  } else {
                    window.open(item.url, "_blank");
                  }
                }}
              >
                {item.name}
              </List.Item>
            )}
          />
          <ConfigProvider theme={themeConfig()}>
            <Upload.Dragger {...props} onChange={e => this.onChange(e)}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t("documents.clickOrDrag")}</p>
            </Upload.Dragger>
          </ConfigProvider>
        </Spin>
        <Modal
          open={this.state.modal1}
          onCancel={() => this.setState({ modal1: false })}
          maskClosable={false}
          width={"80%"}
        >
          <Col span={24} style={{ minHeight: "30%" }}>
            {this.state.forge && (
              <ForgeViewer
                style={{ height: "600px" }}
                version="6.0"
                urn="dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YTM2MHZpZXdlci90MTYwNTU0NTE3Njk3OV8wMjA4NTIyNDQ2OTM0MDY1NjNfMTYwNTU0NTE3NzY3NC5kd2c"
                view={this.state.viewer}
                headless={false}
                onViewerError={this.handleViewerError.bind(this)}
                onTokenRequest={this.handleTokenRequested.bind(this)}
                onDocumentLoad={this.handleDocumentLoaded3d.bind(this)}
                onDocumentError={this.handleDocumentError.bind(this)}
                onModelLoad={this.handleModelLoaded.bind(this)}
                onModelError={this.handleModelError.bind(this)}
              />
            )}
          </Col>
        </Modal>
        <Modal
          open={this.state.modal2}
          onCancel={() => this.setState({ modal2: false })}
          maskClosable={false}
          width={"80%"}
        >
          <Col span={24} style={{ minHeight: "30%" }}>
            {this.state.forge && (
              <ForgeViewer
                style={{ height: "600px" }}
                version="6.0"
                urn="dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YTM2MHZpZXdlci90MTYwNTU0NTI5MzE1OF8wOTE0OTExODI2OTA5Mzc0OF8xNjA1NTQ1MjkzMTY1LmR3Zw"
                view={this.state.viewer}
                headless={false}
                onViewerError={this.handleViewerError.bind(this)}
                onTokenRequest={this.handleTokenRequested.bind(this)}
                onDocumentLoad={this.handleDocumentLoaded2d.bind(this)}
                onDocumentError={this.handleDocumentError.bind(this)}
                onModelLoad={this.handleModelLoaded.bind(this)}
                onModelError={this.handleModelError.bind(this)}
              />
            )}
          </Col>
        </Modal>
      </div>
    );
  }
}
