import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import BetterPolygon from "@recogito/annotorious-better-polygon";
import * as AnnotoriousOpenSeaDragon from "@recogito/annotorious-openseadragon";
import SelectorPack from "@recogito/annotorious-selector-pack";
import ShapeLabelsFormatter from "@recogito/annotorious-shape-labels";
import OpenSeadragon from "openseadragon";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import "@recogito/annotorious-openseadragon/dist/annotorious.min.css";
import "@recogito/annotorious/dist/annotorious.min.css";
import "./ImageAnnotatorStyle.css";
// import { setLocale } from "@recogito/recogito-client-core";

import {
  BorderOutlined,
  CheckSquareOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  LeftOutlined,
  MonitorOutlined,
  RightOutlined,
  UnorderedListOutlined,
  ZoomInOutlined,
  ZoomOutOutlined
} from "@ant-design/icons";
import { Button, Card, Col, Divider, Drawer, Dropdown, Input, List, Row, Tooltip } from "antd";
import dayjs from "dayjs";
import _, { filter, forEach, orderBy, remove } from "lodash";
import { toJS } from "mobx";
import { HuePicker } from "react-color";
import { useRouteMatch } from "react-router-dom";
import useLanguageEffect from "../hook/useLanguageEffect";
import { CircleIcon, PolygonIcon, RectIcon } from "./ImageAnnotatorIcons";

const ImageAnnotatorComponent = ({
  t,
  submissionId,
  url = "640px-Hallstatt.jpg",
  allowClaim = false,
  allowModify = true,
  currentAnnotations = [],
  onAnnotationsUpdated
}) => {
  const imgEl = useRef();
  const route = useRouteMatch();
  const { i18n } = useTranslation();

  const innerHeight = localStorage.getItem(`platform`) === "web" ? window.innerHeight * 0.8 : screen.height * 0.5;

  const [annoInstance, setAnnoInstance] = useState();
  const [annoViewerInstance, setAnnoViewerInstance] = useState();

  const [idsToAutoClaim, setIdsToAutoClaim] = useState(undefined);

  const [currentPage, setCurrentPage] = useState(0);

  // Any event that was fired?
  const [annoInstanceEventChange, _setAnnoInstanceEventChange] = useState();
  const annoInstanceEventChangeRef = useRef(annoInstanceEventChange);

  const setAnnoInstanceEventChange = data => {
    annoInstanceEventChangeRef.current = data;
    _setAnnoInstanceEventChange(data);
  };
  const [toolbarExpanded, setToolbarExpanded] = useState(true);
  const [mode, setMode] = useState("preview");

  const [annotations, setAnnotations] = useState([]);
  const [annotationsDrawerOpen, setAnnotationsDrawerOpen] = useState(false);
  const [annotationsDrawerSearchValue, setAnnotationsDrawerSearchValue] = useState();

  const [currentColor, _setCurrentColor] = useState({ r: 0, g: 255, b: 0, a: 1 });

  const currentColorRef = useRef(currentColor);

  useLanguageEffect(() => {
    applyTranslationsToAnnotations();
  }, []);

  const applyTranslationsToAnnotations = () => {
    let buttonElements = [...document.querySelectorAll("button.r6o-btn.outline")];
    const title = [...document.getElementsByClassName("delete-annotation")];
    const inputPlaceholder = [...document.getElementsByClassName("r6o-autocomplete")];
    inputPlaceholder.map(div => {
      const inputElement = div.querySelector("input");
      inputElement.placeholder = t("common.addTag");
      inputElement.addEventListener("input", event => {
        event.stopPropagation();
      });
      return div;
    });

    title.map(button => {
      button.setAttribute("title", t("common.delete"));
      return button;
    });

    buttonElements.forEach(button => {
      while (button.firstChild) {
        button.removeChild(button.firstChild);
      }
      const textNode = document.createTextNode(t("common.cancel"));
      button.appendChild(textNode);
    });
  };

  const setCurrentColor = data => {
    currentColorRef.current = data;
    _setCurrentColor(data);
  };

  const onUpdated = (annotationsAnno = undefined, emptyId) => {
    if (!annotationsAnno) annotationsAnno = annoInstance.getAnnotations();
    else annoInstance.setAnnotations(annotationsAnno);

    forEach(currentAnnotations, annotation => {
      if (annotation.body[0].page && annotation.body[0].page !== currentPage) {
        annotationsAnno.push(annotation);
      }
    });

    setAnnotations(annotationsAnno);

    if (onAnnotationsUpdated) {
      if (idsToAutoClaim) {
        claim({ id: `#${idsToAutoClaim}` }, annotationsAnno).then(() => {
          onAnnotationsUpdated({
            annotations: annotationsAnno,
            claims: annotationsAnno
              .filter(a => isClaimed(a) === true)
              .map(a => getFirstTextBody(a).value)
              .join(", ")
          });
        });
      } else if (emptyId) {
        const id = annotationsAnno[0]?.id.split("#")[1];

        claim({ id: `#${id}` }, annotationsAnno, emptyId).then(() => {
          onAnnotationsUpdated({
            annotations: annotationsAnno,
            claims: annotationsAnno
              .filter(a => isClaimed(a) === true)
              .map(a => getFirstTextBody(a).value)
              .join(", ")
          });
        });
      } else {
        onAnnotationsUpdated({
          annotations: annotationsAnno,
          claims: annotationsAnno
            .filter(a => isClaimed(a) === true)
            .map(a => getFirstTextBody(a).value)
            .join(", ")
        });
      }
    }
  };

  let mouseIsDown = false;
  let mouseClickTimer = undefined;
  const currentPlatform = localStorage.getItem("platform");
  const host = localStorage.getItem("host") + "/";

  useEffect(() => {
    if (annoInstance) {
      annoInstance.on("createAnnotation", async annotation => {
        let newAnnotation = annoInstance.selectAnnotation(annotation.id);

        newAnnotation.body.push({ type: "style", purpose: "style", value: currentColorRef.current });

        newAnnotation.body[0].page = currentPage;

        // setAnnotations(prev => [...prev, newAnnotation]);

        await annoInstance.updateSelected(newAnnotation, true);
        await annoInstance.saveSelected();
      });
    }
  }, [currentPage, annoInstance, annotations]);

  const onAnnotationAdd = useCallback(() => {
    if (annoInstance && currentAnnotations) {
      annoInstance.setAnnotations(filter([..._.uniqBy(currentAnnotations, x => x.id)], v => v.body[0].page === currentPage));
      setAnnotations(filter([..._.uniqBy(currentAnnotations, x => x.id)], v => v.body[0].page === currentPage));
    }
  }, [currentAnnotations, annoInstance, currentPage]);

  useEffect(() => {
    onAnnotationAdd();
  }, [currentAnnotations, annoInstance, currentPage]);

  useEffect(() => {
    let annotorious = null;
    let pageMode = "single";

    let openSeaDragronOptions = {
      tileSources: {
        type: "image",
        url: host + url
      }
    };

    if (url.startsWith("[")) {
      const urlParsed = JSON.parse(url);

      const linksArray = urlParsed.map((x, i) => {
        return {
          type: "image",
          url: host + x
        };
      });

      openSeaDragronOptions = {
        tileSources: linksArray,
        sequenceMode: true,
        showReferenceStrip: true,
        referenceStripScroll: "vertical",
        showNavigator: true,
        navigatorPosition: "BOTTOM_RIGHT",
        nextButton: "next"
      };

      pageMode = "multiple";
    }

    if (imgEl.current) {
      const viewer = OpenSeadragon({
        ...openSeaDragronOptions,
        id: "openseadragon",
        maxZoomLevel: currentPlatform === "mobile" ? 30 : 10,
        gestureSettingsTouch: {
          pinchRotate: false
        },
        showNavigationControl: false,
        constrainDuringPan: true
      });
      setAnnoViewerInstance(viewer);

      annotorious = AnnotoriousOpenSeaDragon(viewer, {
        image: imgEl.current,
        formatters: [colorFormatter, ShapeLabelsFormatter()],
        allowEmpty: false,
        locale: i18n.language,
        readOnly: !allowModify,
        disableEditor: !allowModify,
        widgets: [{ widget: "TAG", vocabulary: [] }]
      });

      annotorious.setAuthInfo({
        id: localStorage.getItem(`id`),
        displayName: localStorage.getItem(`username`)
      });

      annotorious.on("updateAnnotation", (annotation, previous) => {
        annotation.body.length < previous.body.length
          ? setAnnoInstanceEventChange({ type: "updateAnnotation", data: { previous, annotation } })
          : setAnnoInstanceEventChange({ type: "updateAnnotation", data: { annotation, previous } });
        if (annotation.body.filter(x => x.purpose === "tagging").length === 0)
          annotorious.removeAnnotation(annotation.id);
      });

      annotorious.on("deleteAnnotation", annotation => {
        setAnnoInstanceEventChange({ type: "deleteAnnotation", data: { annotation } });
      });

      annotorious.on("selectAnnotation", (annotation, element) => {
        setAnnoInstanceEventChange({ type: "selectAnnotation", data: { annotation, element } });
      });

      annotorious.on("cancelSelected", selection => {
        console.log("cancelSelected", selection);
        setAnnoInstanceEventChange({ type: "cancelSelected", data: { selection } });

        if (selection.body.length > 0 && selection.body.filter(x => x.purpose === "tagging").length === 0)
          annotorious.removeAnnotation(selection.id);
      });

      BetterPolygon(annotorious);
      SelectorPack(annotorious, { tools: ["ellipse", "circle", "polygon", "rect"] });

      if (currentAnnotations) {
        annotorious.setAnnotations(filter([...currentAnnotations], v => v.body[0].page === currentPage));
        setAnnotations(filter([...currentAnnotations], v => v.body[0].page === currentPage));
        // annotorious.setAnnotations([...currentAnnotations]);
        // setAnnotations([...currentAnnotations]);
      }

      viewer.addHandler("canvas-press", event => {
        if (!allowClaim) return;

        clearTimeout(mouseClickTimer);
        mouseIsDown = true;
        mouseClickTimer = setTimeout(() => {
          if (mouseIsDown) {
            var webPoint = event.position;
            var viewportPoint = viewer.viewport.pointFromPixel(webPoint);
            var imagePoint = viewer.viewport.viewportToImageCoordinates(viewportPoint);
            createCircleAnnotationOnLongClick(imagePoint.x, imagePoint.y, annotorious);
          }
        }, 2000);
      });

      viewer.addHandler("page", data => {
        setCurrentPage(data.page);
      });

      setAnnoInstance(annotorious);

      viewer.addHandler("canvas-release", event => {
        mouseIsDown = false;
      });
    }

    // = = Enter click
    function handleKeyDown(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        const buttons = [...document.getElementsByClassName("r6o-btn")].filter(x => x.innerText === "Ok"); //.click();
        if (buttons.length > 0) buttons[0].click();
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
      annotorious.destroy();
    };
  }, []);

  useEffect(() => {
    if (
      annoInstanceEventChange?.type === "updateAnnotation" &&
      annoInstanceEventChange?.data?.annotation?.body.length < annoInstanceEventChange?.data?.previous?.body.length
    ) {
      onUpdated(undefined, "emptyId");
      return;
    }
    if (!annoInstanceEventChange) return;

    if (annoInstanceEventChange.type === "updateAnnotation") {
      onUpdated();
    }
    if (annoInstanceEventChange.type === "deleteAnnotation") {
      onUpdated();
    }
    if (annoInstanceEventChange.type === "cancelSelected") {
    }
    if (annoInstanceEventChange.type === "selectAnnotation") {
      if (allowClaim) {
        const permissionToCheck = filter(annoInstanceEventChange.data.annotation?.body, o => {
          return o.value === "\u{2714}" || o.value === "✔";
        });

        if (permissionToCheck.length >= 1) unclaim(annoInstanceEventChange.data.annotation);
        else claim(annoInstanceEventChange.data.annotation);
      }
    }
  }, [annoInstanceEventChange]);

  const getFirstTextBody = annotation => {
    if (!annotation.body) return;

    const bodies = Array.isArray(annotation.body) ? annotation.body : [annotation.body];

    return bodies.find(b => b.type === "TextualBody");
  };

  const getClaimStatus = annotation => {
    if (!annotation.body) return;

    const bodies = Array.isArray(annotation.body) ? annotation.body : [annotation.body];

    var claim = bodies.find(x => x.purpose.toLowerCase() === "tagging" && x.value.toLowerCase() === "\u{2714}");
    if (!claim) return;
    return `Claimed by ${claim.creator.name} on ${claim.created
        .replace("T", " ")
        .replace("Z", " ")
        .split(".")[0]
      }`;
  };

  const colorFormatter = annotation => {
    if (
      annotation.bodies.find(
        x => x.purpose.toLowerCase() === "tagging" && x.value.toLowerCase() === "\u{2714}"
        // && x.submissionId === submissionId
      ) != null
    )
      return {
        style: "stroke-width: 2; stroke: rgba(11, 11, 11, 0.8); fill: rgba(11, 11, 11, 0.3);"
      };

    var styleBody = annotation.bodies.find(x => x.type === "style");
    if (styleBody != null)
      return {
        style: `stroke-width: 2; stroke: rgba(${styleBody.value.r}, ${styleBody.value.g}, ${styleBody.value.b}, 0.8); fill: rgba(${styleBody.value.r}, ${styleBody.value.g}, ${styleBody.value.b}, 0.3);`
      };

    return {};
  };

  const getFilteredAnnotations = () => {
    if (!annotationsDrawerSearchValue) return [...orderBy(annotations, x => getFirstTextBody(x).value, "asc")];
    return annotations.filter(x =>
      getFirstTextBody(x)
        .value.toLowerCase()
        .includes(annotationsDrawerSearchValue.toLowerCase())
    );
  };

  const claim = async (annotation, localAnnotations = undefined, emptyId) => {
    setIdsToAutoClaim(undefined);
    if (!localAnnotations) localAnnotations = annotations;
    let selected = localAnnotations.find(x => x.id === annotation.id);
    if (!selected || selected === null) return;
    if (isClaimed(annotation)) return;

    selected.body.push({
      type: "TextualBody",
      purpose: "tagging",
      value: "\u{2714}",
      creator: {
        id: localStorage.getItem(`id`),
        name: localStorage.getItem(`username`)
      },
      // submissionId: submissionId,
      created: dayjs().toJSON()
    });
    !emptyId && onUpdated(localAnnotations);
    annoInstance.cancelSelected();
  };

  const isClaimed = annotation => {
    let foundAnnotation = annoInstance.getAnnotationById(annotation.id);

    if (!foundAnnotation || foundAnnotation === null) return false;

    if (
      foundAnnotation.body.find(
        x => x.purpose === "tagging" && x.value === "\u{2714}" //&& x.submissionId === submissionId
      ) == null
    )
      return false;
    return true;
  };

  const unclaim = async annotation => {
    let selected = annotations.find(x => x.id === annotation.id);
    if (!selected || selected === null) return;
    if (!isClaimed(annotation)) return;

    remove(selected.body, x => x.purpose === "tagging" && x.value === "\u{2714}"); // && x.submissionId === submissionId);
    onUpdated(annotations);
    annoInstance.cancelSelected();
  };

  const deleteAnnotation = async annotation => {
    annoInstance.removeAnnotation(annotation.id);
    onUpdated();
  };

  const createCircleAnnotationOnLongClick = (x, y, annotorious) => {
    const id = uuidv4();
    const anno = {
      "@context": "http://www.w3.org/ns/anno.jsonld",
      type: "Annotation",
      body: [
        {
          type: "style",
          purpose: "style",
          page: currentPage,
          value: {
            r: 0,
            g: 8,
            b: 255,
            a: 1
          }
        }
      ],
      target: {
        source: "https://localhost:5001/api/svgs/formSubmission/04e8d4a0-c8e8-489b-4a05-08dc70053c66.jpg",
        selector: {
          type: "SvgSelector",
          value: '<svg><circle cx="' + x + '" cy="' + y + '" r="100"></circle></svg>'
        }
      },
      id: "#" + id + ""
    };
    annotorious.addAnnotation(anno);
    setIdsToAutoClaim(id);
    const selected = annotorious.selectAnnotation(anno.id);
  };

  return (
    <>
      {mode === "edit" && (
        <Row gutter={12}>
          <Col span={12}>
            <HuePicker
              width="100%"
              color={currentColor}
              onChange={e => {
                setCurrentColor(e.rgb);
              }}
            />
          </Col>
        </Row>
      )}

      <div style={{ position: "relative" }}>
        <Card styles={{ body: { padding: 4 } }}>
          <div
            style={{
              backgroundColor: "#efefef",
              border: "1px solid #cecece",
              position: "absolute",
              top: 10,
              right: 20,
              zIndex: 49
            }}
            id="annotator-buttons"
          >
            {toolbarExpanded && (
              <>
                <Button type="text" size="large" icon={<RightOutlined />} onClick={() => setToolbarExpanded(false)} />

                {mode === "edit" && allowModify === true && localStorage.getItem(`platform`) === "web" && (
                  <>
                    <Tooltip title={t("ImageAnnotator.circle")} placement="top">
                      <Button
                        type="text"
                        size="large"
                        id="image-annot-types"
                        icon={<CircleIcon />}
                        onClick={() => {
                          annoInstance.setDrawingEnabled(true);
                          annoInstance.setDrawingTool("circle");
                        }}
                      />
                    </Tooltip>
                    <Tooltip title={t("ImageAnnotator.rect")} placement="top">
                      <Button
                        type="text"
                        size="large"
                        id="image-annot-types"
                        icon={<RectIcon />}
                        onClick={() => {
                          annoInstance.setDrawingEnabled(true);
                          annoInstance.setDrawingTool("rect");
                        }}
                      />
                    </Tooltip>
                    <Tooltip title={t("ImageAnnotator.polygon")} placement="top">
                      <Button
                        type="text"
                        size="large"
                        id="image-annot-types"
                        icon={<PolygonIcon />}
                        onClick={() => {
                          annoInstance.setDrawingEnabled(true);
                          annoInstance.setDrawingTool("polygon");
                        }}
                      />
                    </Tooltip>
                    <Divider type="vertical" />
                  </>
                )}

                {mode !== "preview" && (
                  <Tooltip title={t("ImageAnnotator.previewMode")} placement="top">
                    <Button
                      type="text"
                      size="large"
                      icon={<EyeOutlined />}
                      onClick={() => {
                        annoInstance.setDrawingEnabled(false);
                        setMode(`preview`);
                      }}
                    />
                  </Tooltip>
                )}

                {localStorage.getItem(`platform`) === "web" && (
                  <>
                    {allowModify === true && mode !== "edit" && (
                      <Tooltip title={t("ImageAnnotator.editMode")} placement="top">
                        <Button
                          type="text"
                          size="large"
                          icon={<EditOutlined />}
                          onClick={() => {
                            annoInstance.setDrawingEnabled(true);
                            setMode(`edit`);
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                )}

                <Divider type="vertical" />

                <Tooltip title={t("ImageAnnotator.zoomIn")} placement="top">
                  <Button
                    type="text"
                    size="large"
                    icon={<ZoomInOutlined />}
                    onClick={() => {
                      if (annoInstance._app.current.annotationLayer.viewer.viewport._oldZoom >= 10) return;
                      annoInstance._app.current.annotationLayer.viewer.viewport.zoomTo(
                        annoInstance._app.current.annotationLayer.viewer.viewport._oldZoom + 0.5
                      );
                    }}
                  />
                </Tooltip>

                <Dropdown
                  placement="bottomRight"
                  trigger={"click"}
                  overlayStyle={{ paddingRight: 50 }}
                  menu={{
                    items: [0.2, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 6, 7, 8, 9, 10].map(opt => ({
                      key: `${opt}`,
                      label: `${opt * 100}%`
                    })),
                    onClick: e => annoInstance._app.current.annotationLayer.viewer.viewport.zoomTo(+e.key)
                  }}
                >
                  <Button type="text" size="large" icon={<MonitorOutlined />} />
                </Dropdown>

                <Tooltip title={t("ImageAnnotator.zoomOut")} placement="top">
                  <Button
                    type="text"
                    size="large"
                    icon={<ZoomOutOutlined />}
                    onClick={() => {
                      if (annoInstance._app.current.annotationLayer.viewer.viewport._oldZoom < 1) return;
                      annoInstance._app.current.annotationLayer.viewer.viewport.zoomTo(
                        annoInstance._app.current.annotationLayer.viewer.viewport._oldZoom - 0.5
                      );
                    }}
                  />
                </Tooltip>
              </>
            )}
            {!toolbarExpanded && (
              <Button type="text" size="large" icon={<LeftOutlined />} onClick={() => setToolbarExpanded(true)} />
            )}

            <Divider type="vertical" />
            <Tooltip title={t("common.annotations")} placement="top">
              <Button
                type="text"
                size="large"
                icon={<UnorderedListOutlined />}
                onClick={() => setAnnotationsDrawerOpen(true)}
              />
            </Tooltip>
          </div>

          <div id="openseadragon" style={{ width: "100%", height: innerHeight }} ref={imgEl} />
        </Card>
      </div>

      <Drawer
        title={t("common.selections")}
        open={annotationsDrawerOpen}
        onClose={() => setAnnotationsDrawerOpen(false)}
        size="large"
        style={{ zIndex: 12000, marginTop: 50 }}
        styles={{
          wrapper: {
            height: "92%"
          }
        }}
      >
        <Input
          placeholder={t("common.search")}
          value={annotationsDrawerSearchValue}
          onChange={e => setAnnotationsDrawerSearchValue(e.target.value)}
        />
        <List
          size="small"
          locale={{ emptyText: t("common.noDataEntry") }}
          dataSource={getFilteredAnnotations()}
          renderItem={item => {
            return (
              <List.Item
                actions={[
                  <>
                    {allowClaim && !isClaimed(item) && (
                      <Tooltip title={`Claim`}>
                        <Button type="text" icon={<BorderOutlined />} onClick={async () => await claim(item)} />
                      </Tooltip>
                    )}
                    {isClaimed(item) && (
                      <Button
                        type="text"
                        icon={<CheckSquareOutlined />}
                        disabled={!allowClaim}
                        onClick={async () => await unclaim(item)}
                      />
                    )}
                  </>,
                  <>
                    {allowModify && (
                      <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={async () => await deleteAnnotation(item)}
                      />
                    )}
                  </>
                ]}
              >
                {getFirstTextBody(item).value}
              </List.Item>
            );
          }}
        />
      </Drawer>
    </>
  );
};

export default withTranslation()(observer(ImageAnnotatorComponent));
