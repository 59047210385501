import Icon from "@ant-design/icons";
import React from "react";

const Circle = () => (
    <svg viewBox="0 0 70 50">
        <g>
            <circle cx="35" cy="25" r="23"></circle>
            <g class="handles">
                <circle cx="35" cy="2" r="5"></circle>
                <circle cx="12" cy="25" r="5"></circle>
                <circle cx="58" cy="25" r="5"></circle>
                <circle cx="35" cy="48" r="5"></circle>
            </g>
        </g>
    </svg>
);

export const CircleIcon = (props) => <Icon component={Circle} {...props} />;

const Ellipse = () => (
    <svg viewBox="0 0 70 50">
        <g>
            <ellipse cx="35" cy="25" rx="30" ry="19"></ellipse>
            <g class="handles">
                <circle cx="35" cy="6" r="5"></circle>
                <circle cx="5" cy="25" r="5"></circle>
                <circle cx="65" cy="25" r="5"></circle>
                <circle cx="35" cy="44" r="5"></circle>
            </g>
        </g>
    </svg>
);

export const EllipseIcon = (props) => <Icon component={Ellipse} {...props} />;

const Polygon = () => (
    <svg viewBox="0 0 70 50">
    <g>
      <path d="M 5,14 60,5 55,45 18,38 Z"></path>
      <g class="handles">
        <circle cx="5" cy="14" r="5"></circle>
        <circle cx="60" cy="5" r="5"></circle>
        <circle cx="55" cy="45" r="5"></circle>
        <circle cx="18" cy="38" r="5"></circle>
      </g>
    </g>
  </svg>
);

export const PolygonIcon = (props) => <Icon component={Polygon} {...props} />;

const Rect = () => (
    <svg viewBox="0 0 70 50">
    <g>
      <rect x="12" y="10" width="46" height="30"></rect>
      <g class="handles">
        <circle cx="12" cy="10" r="5"></circle>
        <circle cx="58" cy="10" r="5"></circle>
        <circle cx="12" cy="40" r="5"></circle>
        <circle cx="58" cy="40" r="5"></circle>
      </g>
    </g>
  </svg>
);

export const RectIcon = (props) => <Icon component={Rect} {...props} />;