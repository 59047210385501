import { makeAutoObservable } from "mobx";

import env from "./../env.json";

class ConfigStore {
  constructor() {
    makeAutoObservable(this);

    this.setBackendUrl();
    this.setPlatform();
  }

  backendUrl = "";
  backendUrlHost = "";
  backendHeaders = { mode: "no-cors" };
  currentMenu = null;
  isOffline = false;
  platform = undefined;
  id = null;
  getAppConfig() {
    return fetch("appConfig.json").then(r => r.json());
  }
  getCurrentMenu = async (menu, id) => {
    this.currentMenu = menu;
    this.id = id;

    return menu;
  };
  setBackendUrl = host => {
    if (!host && localStorage.getItem(`host`)) host = localStorage.getItem(`host`);
    if (!host) host = env.ApiUrl;

    this.backendUrl = host + "/api/";
    this.backendUrlHost = host;

    localStorage.setItem(`host`, host);
  };

  setPlatform = (platform = undefined) => {
    if (!platform && localStorage.getItem(`platform`)) platform = localStorage.getItem(`platform`);
    if (!platform) platform = env.Platform;

    this.platform = platform;

    localStorage.setItem(`platform`, this.platform);
  };

  getDefaultRoute = () => {
    const appConfig = JSON.parse(localStorage.getItem(`appConfig`));
    if (!localStorage.getItem(`jwtToken`)) return `/utils/login`;

    if (this.platform === "mobile") return `/${localStorage.getItem(`projectName`)}/collect/entries`;

    if (
      localStorage.getItem(`jwtToken`) &&
      localStorage.getItem(`projectName`) &&
      localStorage.getItem(`projectName`) === "bas"
    )
      return `/${localStorage.getItem(`projectName`)}/workflows`;
    const defaultRoute = JSON.parse(localStorage.getItem(`projectMenu`));
    const existDefaultRoute = defaultRoute?.filter(route => route.defaultRoute) || [];
    const copyConfigJson = existDefaultRoute.length
      ? existDefaultRoute[0]?.defaultRoute
      : appConfig.router.defaultRoute;
    if (localStorage.getItem(`projectName`)) {
      return `/${localStorage.getItem(`projectName`)}${copyConfigJson}`;
    }
    return `/${localStorage.getItem(`projectName`)}${appConfig.router.defaultRoute}`;
  };
}

export default new ConfigStore();
