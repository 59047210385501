import { message } from "antd";
import i18next from "i18next";
import { makeAutoObservable, toJS } from "mobx";
import BackendService from "../Common/BackendService";
import GraphQlService from "../Common/GraphQlService";
import UserStore from "../Common/Users/UserStore";
import { includes, isEmpty } from "lodash";
export class ViewGeneratorStore {
  isLoading = false;

  data = undefined;

  hubId = undefined;
  hubConfig = undefined;
  assetDefinition = undefined;

  tableConfig = { page: 1, pageSize: 20, total: 0, sorter: {} };

  basicSearchValue = undefined;
  advanceSearchDrawer = { isVisible: false };

  uiConfig = undefined;

  title = undefined;

  tableRowSelectionEnabled = false;

  containersApi = new BackendService("containers");

  currentPageContainerIds = [];
  selectedContainerIds = [];

  exportGridCallback = null;
  expandAllCallback = null;
  collpaseAllCallback = null;
  toggleChartCallback = null;

  constructor() {
    makeAutoObservable(this);
  }

  modifyHubConfig = newHubConfig => {
    this.hubConfig = newHubConfig;
  };

  prepareDataSource = () => {
    if (this.hubConfig.DataSourceNew) {
      return this.prepareDataSourceV2(this.hubConfig.DataSourceNew);
    }

    let dataSource = this.hubConfig.DataSource;
    if (!dataSource) dataSource = "";
    if (this.tableConfig) {
      dataSource = dataSource.replace("$page", this.tableConfig.page);
      dataSource = dataSource.replace("$pageSize", this.tableConfig.pageSize);
      dataSource = dataSource.replace("$orderBy", JSON.stringify(this.tableConfig.sorter).replaceAll('"', '\\"'));
    } else {
      dataSource = dataSource.replace("$page", null);
      dataSource = dataSource.replace("$pageSize", null);
      dataSource = dataSource.replace("$orderBy", "");
    }

    if (this.advanceSearchDrawer.jql) {
      dataSource = dataSource.replace("$jql", JSON.stringify(this.advanceSearchDrawer.jql).replaceAll('"', '\\"'));
      if (this.advanceSearchDrawer.jqlAssetType)
        dataSource = dataSource.replace("$jqlAssetType", this.advanceSearchDrawer.jqlAssetType);
    } else {
      dataSource = dataSource.replace("$jql", "");
      dataSource = dataSource.replace("$jqlAssetType", "");
    }

    if (!this.hubConfig.GroupByField) dataSource = dataSource.replace("$groupBy", "");
    else dataSource = dataSource.replace("$groupBy", this.hubConfig.GroupByField);

    if (this.basicSearchValue && !this.advanceSearchDrawer.jql)
      dataSource = dataSource.replace("$basicSearch", this.basicSearchValue);
    else dataSource = dataSource.replace("$basicSearch", "");

    return dataSource;
  };

  prepareDataSourceV2 = config => {
    console.log("data source", toJS(config));

    var queryString = `{ ${config.containerEndpoint ?? "containersHub"}(hubConfigId: \"${this.hubId}\"`;

    if (this.advanceSearchDrawer.jql) {
      queryString += `, jql: "${JSON.stringify(this.advanceSearchDrawer.jql).replaceAll('"', '\\"')}"`;
      if (this.advanceSearchDrawer.jqlAssetType)
        queryString += `, jqlAssetType: "${this.advanceSearchDrawer.jqlAssetType}"`;
    }

    if (this.tableConfig) {
      queryString += `, page: ${this.tableConfig.page}`;
      queryString += `, pageSize: ${this.tableConfig.pageSize}`;
      if (this.tableConfig.sorter && JSON.stringify(this.tableConfig.sorter).replaceAll('"', '\\"') != "{}")
        queryString += `, orderBy: "${JSON.stringify(this.tableConfig.sorter).replaceAll('"', '\\"')}"`;
    }

    if (this.basicSearchValue && !this.advanceSearchDrawer.jql)
      queryString += `, basicSearch: "${this.basicSearchValue}"`;

    queryString += `) ` + config.fields;
    queryString += "}";

    return queryString;
  };

  loadData = async () => {
    this.data = undefined;
    this.isLoading = true;
    let dataSource = this.prepareDataSource();

    const graphQlService = new GraphQlService();
    const dataResponse = await graphQlService.get(dataSource);
    if (dataResponse.errors) {
      graphQlService.displayErrors(dataResponse.errors);
    } else {
      this.data = dataResponse.data;
      this.isLoading = false;
    }
  };

  loadGroupedData = async (groupByAssetDefField, groupByValue) => {
    const graphQlService = new GraphQlService();
    const dataResponse = await graphQlService.get(
      `{containers(where:{path:"${
        groupByAssetDefField.dataIndex === "payload"
          ? `payload.${groupByAssetDefField.dataIndexField}`
          : groupByAssetDefField.dataIndexField
      }",comparison:EQUAL,value:"${groupByValue}"}){id,rootId,payload,name,rootId,createdBy{username},createdDateTime,riskStatus{icon,name,color,statusId,order}}}`
    );
    if (dataResponse.errors) graphQlService.displayErrors(dataResponse.errors);

    return dataResponse.data.containers
      .filter(x => x)
      .map(x => ({ ...x, payload: JSON.parse(x.payload || "{}") }))
      .filter(x => x.payload && x.payload.Id);
  };

  downloadExcel = async () => {
    this.isLoading = true;
    let dataSource = this.prepareDataSource();

    var conditionsStartIndex = dataSource.indexOf("(");
    var conditionsEndIndex = dataSource.indexOf(")", conditionsStartIndex);

    const graphQlService = new GraphQlService();
    const dataResponse = await graphQlService.get(
      `{ containersToExcel${dataSource.substring(conditionsStartIndex, conditionsEndIndex) +
        `, uiHubId: "${this.hubId}")`} }`
    );
    if (dataResponse.errors) graphQlService.displayErrors(dataResponse.errors);
    else {
      var a = document.createElement("a"); //Create <a>
      a.href = "data:application/octet-stream;base64," + dataResponse.data.containersToExcel;
      a.download = "Export.xlsx";
      a.click();
      this.isLoading = false;
    }
  };

  remove = id => {
    this.containersApi.delete(id).then(r => {
      message.success(i18next.t("documents.removeDocMessage"));
      this.loadData();
    });
  };

  removeWithRelations = id => {
    const graphQlService = new GraphQlService();
    graphQlService
      .post(`mutation post($data: DeleteContainerWithRelationsCommand) { containerDeleteWithRelations(data: $data) }`, {
        data: { id: id }
      })
      .then(r => {
        if (r.errors) {
          this.graphQlService.displayErrors(r.errors);
          return;
        }
        message.success(i18next.t("documents.removeDocMessage"));
        this.loadData();
      });
  };

  updateContainerProperty = async (containerId, fieldId, value) => {
    const graphQlService = new GraphQlService();
    const date = new Date();
    const valueWithDateAndUser = {
      ...JSON.parse(value),
      user: {
        name: localStorage.getItem(`username`),
        email: localStorage.getItem(`email`),
        date: date
      }
    };
    graphQlService
      .post(
        `mutation updateProperty($containerId:String!, $fieldId:String!, $value:String!) { updateContainerProperty(containerId:$containerId,fieldId:$fieldId,value:$value) }`,
        {
          containerId,
          fieldId,
          value: JSON.stringify(valueWithDateAndUser)
        }
      )
      .then(r => {
        if (r.errors) graphQlService.displayErrors(r.errors);
        this.loadData();
      });
  };

  toBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  uploadMultiPagePdf = async (file, fileName) => {
    const graphQlService = new GraphQlService();
    message.success(i18next.t("tasks.stepTitles.uploadingFile"));

    const res = await graphQlService.post(
      `mutation mutate($file: String, $filename: String, $id: Guid){ formSubmissionUploadMultiPagePdf(file: $file, filename: $filename, id: $id) }`,
      {
        file: await this.toBase64(file),
        filename: fileName,
        id: "00000000-0000-0000-0000-000000000000"
      }
    );

    return res.data.formSubmissionUploadMultiPagePdf;
  };

  uploadFile = async (file, fileName) => {
    if (includes(fileName, "pdf")) {
      return this.uploadMultiPagePdf(file, fileName);
    }

    const graphQlService = new GraphQlService();

    const res = await graphQlService.post(
      `mutation mutate($file: String, $filename: String, $id: Guid){ formSubmissionUploadFile(file: $file, filename: $filename, id: $id) }`,
      {
        file: await this.toBase64(file),
        filename: fileName,
        id: "00000000-0000-0000-0000-000000000000"
      }
    );

    return res.data.formSubmissionUploadFile;
  };
}
export default new ViewGeneratorStore();
