import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";

import { Button, Card, Modal } from "antd";
import { PageHeader } from "@ant-design/pro-layout";

import CommentsComponent from "../../../../Common/Comments/CommentsComponent";
import GraphQlService from "../../../../Common/GraphQlService";
import CommentsStore from "../../../../Common/Comments/CommentsStore";

const DocumentComments = ({ viewingContainerId, t }) => {
  useEffect(() => loadComments(), [viewingContainerId]);

  const getMainButtons = () => {
    var list = [];
    if (CommentsStore.comments.map(x => x.isResolved).filter(x => x === false).length === 0)
      list.push(
        <Button key="3" type="primary" size="small">
          {t("comment.allResolved")}
        </Button>
      );
    else
      list.push(
        <Button
          key="3"
          type="default"
          size="small"
          onClick={() => {
            CommentsStore.resolve(CommentsStore.comments.map(x => x.id)).then(() => loadComments());
          }}
        >
          {t("comment.markAllAsResolved")}
        </Button>
      );
    list.push(<CloseOutlined style={{ cursor: "pointer" }} onClick={() => Modal.destroyAll()} />);
    return list;
  };

  const loadComments = () => {
    if (!viewingContainerId) return;
    CommentsStore.isLoading = true;

    var graphQlService = new GraphQlService();

    graphQlService
      .get(
        `{ comments(objectId: "${viewingContainerId}") { totalEntries list { id content isResolved parentId createdDateTime mainParent { name } createdBy { id username } payload children { id } } } }`
      )
      .then(r => {
        CommentsStore.setData(r.data.comments);
      });
  };

  return (
    <Card
      style={{ padding: "0px !important", boxShadow: "none" }}
      size="small"
      bordered={false}
      loading={CommentsStore.isLoading}
    >
      <PageHeader
        title={t("documents.drawer.comments")}
        extra={getMainButtons()}
        style={{ marginTop: -40 }}
      ></PageHeader>

      <div style={{ marginLeft: 30, marginRight: 30 }}>
        {/* <Input placeholder="Search..." prefix={<SearchOutlined />} onChange={(e) => DocumentCommentStore.filterList(e.target.value)} /> */}
      </div>

      <CommentsComponent canAddNew={true} reload={loadComments} containerId={viewingContainerId} />
    </Card>
  );
};

export default withTranslation()(observer(DocumentComments));
