import { Avatar } from "antd";

export const NameAvatarComponent = ({ name }) => {
    if(!name) return "";
    const initials = name
        .split(" ")
        .map((x) => x.toUpperCase()[0])
        .splice(0, 2)
        .join("");

    const stringToColour = (str) => {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = "#";
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xff;
            colour += ("00" + value.toString(16)).substr(-2);
        }
        return colour;
    };

    return <Avatar style={{ backgroundColor: stringToColour(name) }}>{initials}</Avatar>;
};