import { unregister } from "../../serviceWorker";

export const logout = (history) => {
    const showAlwaysBookmarks = localStorage.getItem("showAlwaysBookmarks")

    localStorage.removeItem(`jwtToken`);

    if (showAlwaysBookmarks === "true") localStorage.setItem("showAlwaysBookmarks", true);

    unregister();
    if (history) history.push(`/utils/login`);
    else window.location.assign("/utils/login");

    return 0;
}
